import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { CreateHolidayDTO } from '../model/createHolidayDTO';
import { UpdateHolidayDTO } from '../model/updateHolidayDTO';
import { PageList } from '../model/pageList';
import { HolidayDTO } from '../model/holidayDTO';
import { HolidayQueryDTO } from '../model/holidayQueryDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HolidayApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getHoliday(queryParams: HolidayQueryDTO): Observable<PageList> {
    const url = this.baseUrl + '/holiday';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

  postHoliday( createHolidayDTO: Array<CreateHolidayDTO>): Observable<Array<HolidayDTO>> {
    const url = this.baseUrl + '/holiday';
    return this.http.post<any>(url, createHolidayDTO);
  }

  deleteHoliday( id: number ): Observable<void> {
    const url = this.baseUrl + '/holiday/'+id+'';
    return this.http.delete<any>(url);
  }

  patchHoliday(id: number,  updateHolidayDTO: UpdateHolidayDTO): Observable<HolidayDTO> {
    const url = this.baseUrl + '/holiday/'+id+'';
    return this.http.patch<any>(url, updateHolidayDTO);
  }

}
