<div class="container-fluid mt-4 user-management clear-user-agent-styles">
  <h2>Leave request</h2>
  <table class="table table-bordered">
    <thead class="user-list">
      <tr>
        <th class="role-bar"></th>
        <th class="user-name">User</th>
        <th class="conector"></th>
        <th>Date From</th>
        <th>Date To</th>
        <th>WorkingDays</th>
        <th>Leave Type</th>
        <th>Half Day</th>
        <th>Comment</th>
        <th>Status</th>
        <th class="conector"></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let leave of leaveList">
        <td class="role-bar" [ngClass]="leave.user.roles"></td>
        <td class="user-name">{{leave.user.firstName}} {{leave.user.lastName}}</td>
        <th class="conector">
          <div class="line"></div>
        </th>
        <td>{{leave.dateFrom}}</td>
        <td>{{leave.dateTo}}</td>
        <td>{{leave.workingDays}}</td>
        <td>{{leave.leaveType.name}}</td>
        <td>{{leave.leaveType.halfDay}}</td>
        <td>{{leave.comment}}</td>

        <td><span class="badge bg-success" *ngIf="leave.status == 'APPROVED'">{{leave.status}}</span>
          <span class="badge bg-warning text-dark" *ngIf="leave.status == 'PENDING'">{{leave.status}}</span>
          <span class="badge bg-danger" *ngIf="leave.status == 'REJECTED'">{{leave.status}}</span>
        </td>
        <th class="conector">
          <div class="line"></div>
        </th>
        <td class="text-center">

          <button *ngIf="leave.status == 'PENDING'" type="button" class="btn hover-primary px-2" data-bs-toggle="modal"
            data-bs-target="#aproveLeave" (click)="setLeaveId(leave.id)">
            <mat-icon class="material-icons-outlined">mode_edit_outline</mat-icon>
          </button>
          <button *ngIf="leave.status !== 'PENDING'" type="button" class="btn px-2" disabled 
            data-bs-target="#aproveLeave" (click)="setLeaveId(leave.id)">
            <mat-icon class="material-icons-outlined">mode_edit_outline</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-modal [modalId]="'aproveLeave'" title="Aprove Leave" [isForm]="true">
  <form>
    <div class="btn-group" role="group" aria-label="Basic outlined example">
      <button type="button" class="btn btn-outline-success" data-bs-dismiss="modal" (click)="approve(leaveId)">Approve</button>
      <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="reject(leaveId)">Reject</button>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlTextarea1" class="form-label">Comment </label>
      <textarea class="form-control" id="comment" rows="1"></textarea>
    </div>
  </form>
</app-modal>