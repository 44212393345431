import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { UpdateOfficeDTO } from '@model/updateOfficeDTO';
import { CountryDTO } from '@model/countryDTO';
import { CountryApiService } from '@api/country.service';
import { ActivatedRoute,Router } from '@angular/router';
import {
  FormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';
@Component({
  selector: 'app-edit-office',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule,AlertComponent],
  templateUrl: './edit-office.component.html',
  styleUrl: './edit-office.component.css',
})
export class EditOfficeComponent {
  office: OfficeDTO | any;
  officeId: number = 0;
  countries: CountryDTO | any;
  myForm: FormGroup;
  formData = {
    name: '',
    country: '',
    description: '',
   
  };
  constructor(
    private fb: FormBuilder,
    private officeApi: OfficeApiService,
    private route: ActivatedRoute,
    private countryApi: CountryApiService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      country: ['', [Validators.required]],
      description: ['', [Validators.required]],
      
    });
  }

  getOffice() {
    this.officeApi.getOfficeById(this.officeId).subscribe((data) => {
      console.log('officeId', this.officeId);
      console.log('value', data);
      this.office = data;
      this.myForm.patchValue(data);
      this.formData = data;
    });
  }
  getCountries() {
    this.countryApi.getCountryList().subscribe((value) => {
      this.countries = value;
    });
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.officeId = id;
    });
    this.getOffice();
    this.getCountries();
  }
  onSubmit(){
  
    console.log("formData",this.formData)
    this.officeApi.patchOffice(this.officeId,this.formData ).subscribe(()=>{
      this.alertService.sendAlertSuccess('The office was updated succesfuly');
      this.router.navigate(['/management/offices']);
    })
  }
}
