import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import moment from 'moment';
import { AttendanceDTO } from '@model/attendanceDTO';
import { AttendanceApiService } from '@api/attendance.service';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { ManagementHeaderComponent } from '@components/menu/management-header/management-header.component';
import { QueryPaginationDTO } from '@model/queryPaginationDTO';

interface GroupedAttendance {
  title: string;
  records: AttendanceDTO[];
}

@Component({
  selector: 'app-attendance',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, ModalComponent, ManagementHeaderComponent],
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  isEntered = false;
  currentDateTime: string = moment().format("YYYY-MM-DD HH:mm:ss");
  attendanceList: Array<AttendanceDTO> = [];
  groupedAttendanceList: Array<GroupedAttendance> = [];
  attendanceWithoutOut: number = 0;
  attendanceInId: number = 0;

  customPeriod: boolean = false;
  startDate: string;
  endDate: string;
  selectedPeriod: string = 'TODAY';
  attendanceForm: FormGroup;

  constructor(private attendanceApi: AttendanceApiService, private fb: FormBuilder) {
    this.attendanceForm = this.fb.group({
      currentDateTime: ['', Validators.required],
      comment: [''],
    });
    this.startDate = '';
    this.endDate = '';
  }

  ngOnInit(): void {
    this.getAttendance(this.selectedPeriod);
  }

  onSubmit(id: number) {
    if (this.attendanceForm.valid) {
      const time = this.attendanceForm.value.currentDateTime.split(" ").join("T") + ".000Z";
      const note = this.attendanceForm.value.comment;
      this.attendanceApi.postAttendance({ id, time, note }).subscribe(() => {
        this.getAttendance(this.selectedPeriod);
      });
    }
  }

  exit(id: number | undefined, note: string) {
    this.checkIfEmpty();
    const time = this.currentDateTime.split(" ").join("T") + ".000Z";
    if (id === undefined) alert("Id can be undefined");
    this.attendanceApi.postAttendance({ id, time, note }).subscribe(() => {
      this.getAttendance(this.selectedPeriod);
    });
  }

  enter(note: string) {
    this.checkIfEmpty();
    const time = this.currentDateTime.split(" ").join("T") + ".000Z";
    this.attendanceApi.postAttendance({ id: undefined, time, note }).subscribe(() => {
      this.getAttendance(this.selectedPeriod);
    });
  }

  delete(id: number) {
    this.attendanceApi.deleteAttendance(id).subscribe(() => {
      this.getAttendance(this.selectedPeriod);
    });
  }

  checkIfEmpty() {
    if (this.currentDateTime === "") {
      this.currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
    }
  }

  getAttendance(period: string = 'TODAY', startDate?: string, endDate?: string) {
    this.attendanceInId = 0;
    this.currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const params: QueryPaginationDTO = {
      page: 0,
      pageSize: 10,
      sort: 'DESC',
      sortedByField: 'createdAt',
      period: period,
    };

    if (period === 'PERIOD') {
      params.startDate = startDate;
      params.endDate = endDate;
    } else {
      const now = moment();
      params.startDate = now.subtract(1, 'year').format("YYYY-MM-DD");
      params.endDate = now.format("YYYY-MM-DD");
    }

    this.attendanceApi.getUsersAttendance(params).subscribe(value => {
      this.attendanceList = value.content;
      const at = this.attendanceList.filter(a => a.timeOut === null);
      this.attendanceWithoutOut = at.length;
      const ids = at.filter(a => moment(a.timeIn).isSame(moment().startOf('day'), 'day')).map(a => a.id);
      if (ids.length > 0) {
        this.attendanceInId = ids[0] as number;
      }
      this.groupAttendanceList(period);
    });
  }

  groupAttendanceList(period: string) {
    const grouped: { [key: string]: GroupedAttendance } = {};

    this.attendanceList.forEach(record => {
      const date = new Date(record.timeIn);
      const day = date.toISOString().split('T')[0];
      const week = this.getWeekNumber(date);
      const month = `${date.getFullYear()}-${date.getMonth() + 1}`;

      const dayTitle = `Ziua: ${day}`;
      const weekTitle = `Săptămâna: ${week}`;
      const monthTitle = `Luna: ${month}`;

      if (period === 'TODAY' && !grouped[dayTitle]) {
        grouped[dayTitle] = { title: dayTitle, records: [] };
      } else if (period === 'THIS_WEEK' && !grouped[weekTitle]) {
        grouped[weekTitle] = { title: weekTitle, records: [] };
      } else if (period === 'THIS_MONTH' && !grouped[monthTitle]) {
        grouped[monthTitle] = { title: monthTitle, records: [] };
      }

      if (period === 'TODAY') {
        grouped[dayTitle].records.push(record);
      } else if (period === 'THIS_WEEK') {
        grouped[weekTitle].records.push(record);
      } else if (period === 'THIS_MONTH') {
        grouped[monthTitle].records.push(record);
      }
    });

    if (period === 'THIS_WEEK' || period === 'THIS_MONTH') {
      this.addMissingDays(grouped, period);
    }

    this.groupedAttendanceList = Object.values(grouped);
  }

  addMissingDays(grouped: { [key: string]: GroupedAttendance }, period: string) {
    const startDate = period === 'THIS_WEEK' ? moment().startOf('week') : moment().startOf('month');
    const endDate = period === 'THIS_WEEK' ? moment().endOf('week') : moment().endOf('month');

    const currentDate = startDate.clone();

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      const day = currentDate.toISOString().split('T')[0];
      const dayTitle = `Ziua: ${day}, Săptămâna: ${this.getWeekNumber(currentDate.toDate())}, Luna: ${currentDate.format('MMMM')}`;
      if (!grouped[dayTitle]) {
        grouped[dayTitle] = { title: dayTitle, records: [] };
      }
      currentDate.add(1, 'day');
    }
  }

  getWeekNumber(date: Date): string {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return `${date.getFullYear()}-S${Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)}`;
  }

  onPeriodChange(event: any) {
    const period = event.target.value;
    this.selectedPeriod = period;
    if (period === 'PERIOD') {
      this.customPeriod = true;
    } else {
      this.customPeriod = false;
      this.getAttendance(period);
    }
  }

  applyCustomPeriod() {
    if (this.startDate && this.endDate) {
      this.getAttendance('PERIOD', this.startDate, this.endDate);
    }
  }
}
