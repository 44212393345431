<!--  -->
<div class="d-flex flex-row mb-5 management-header" style="border-bottom: 1px solid #d2cdcd;
padding-bottom: 1rem;">
    <h2>
        <span style="cursor: pointer;color: #0d6efd;">Office Management </span>
    </h2>
    <button class="ms-auto btn btn-sm btn-primary ps-4 py-2" routerLink="./create">
        <span style="padding-right: 8px;">Add new Office</span>
    </button>
</div>
<div class="container-fluid mt-4 user-management clear-user-agent-styles">

    <table class="table table-bordered">
        <thead class="user-list">
            <tr>
                <th class="role-bar"></th>
                <th>Name</th>
                <th class="conector"></th>
                <th>Country</th>
                <th>Office Members</th>
                <th>Description</th>
                <th class="conector"></th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngFor="let office of offices">
            <tr>
                <td class="role-bar"></td>
                <td class="user-name">
                    {{office.name}}
                </td>
                <th class="conector">
                    <div class="line"></div>
                </th>
                <td class="user-email">
                    {{office.country}}
                </td>
                <td class="user-email">
                    {{office.users.length}}
                </td>
                <td>
                    {{office.description | slice:0:20}}
                    <ng-container *ngIf="office.description.length > 20">
                        <span type="button" [popover]="office.description" [popoverTitle]="office.name"
                            triggers="mouseenter:mouseleave">[...]
                        </span>
                    </ng-container>
                </td>
                <th class="conector">
                    <div class="line"></div>
                </th>
                <td class="text-center" style="width: 150px;">
                    <button class="btn hover-primary px-2" type="button" (click)="navigateToEditOffice(office.id)">
                        <mat-icon class="material-icons-outlined">mode_edit_outline</mat-icon>
                    </button>
                    <button class="btn hover-primary px-2" type="button"
                        (click)="navigateToEditOfficeMembers(office.id)">
                        <mat-icon class="material-icons-outlined">group</mat-icon>
                    </button>
                    <button class="btn hover-primary px-2" type="button btn-danger"
                        (click)="delete(office.id, office.name)">
                        <mat-icon class="material-icons-outlined">delete_forever</mat-icon>
                    </button>

                </td>
            </tr>
            <tr>
                <!-- <app-get-office-users [users]="office.users"></app-get-office-users> -->

        </tbody>
    </table>
</div>