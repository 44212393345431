<div class="container-fluid mt-4">

  <form class="row g-3" (ngSubmit)="onSubmit()" [formGroup]="yearForm">
    <div class="col-md-1 col-sm-2 offset-md-1">
      <label for="year" class="form-label">Year</label>
      <input type="number" class="form-control" id="year" name="year" formControlName="year" (change)="onChangeYear()">
    </div>

    <div class="col-auto">
      <label for="download" class="form-label">Download the annual report</label>
      <button type="submit" class="form-control btn btn-primary mb-3" id="download">Download</button>
    </div>
  </form>

  <div class="row">
    <div class="col-md-10 offset-md-1">
      <h2>User Reports</h2>
      <div *ngFor="let user of users" class="mb-4">
        <h4>{{ user.firstName }} {{ user.lastName }}<button type="button" class="btn btn-outline-primary btn-sm"
            (click)="download(user.id)">Download</button></h4>
        <ul class="list-group">

          <div *ngIf="yearReportByUser(user.id).length === 0; else reportList">
            <!-- Content to display when the list is empty -->
            <p>No reports available about {{ user.firstName }} {{ user.lastName }}</p>
          </div>
          <ng-template #reportList>
            <!-- Content to display when the list is not empty -->
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                *ngFor="let report of yearReportByUser(user.id)">
                {{report.leaveType.name}} ({{ report.year}})
                <span class="badge bg-light text-dark rounded-pill">Days: {{ report.totalLeaveDays}}</span>
                <span class="badge bg-light text-dark rounded-pill">Taken: {{ report.takenLeaveDays}}</span>
                <span class="badge bg-light text-dark rounded-pill">Carried: {{ report.carriedOverDays}}</span>
              </li>
            </ul>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>