import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyDTO } from '@model/companyDTO';
import { CompanyApiService } from '@api/company.service';

@Component({
  selector: 'app-company-info-management',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './company-info-management.component.html',
  styleUrl: './company-info-management.component.css'
})
export class CompanyInfoManagementComponent {
  company: CompanyDTO | undefined
  companyForm: FormGroup = this.fb.group({
    name: [''],
    phone: ['']
  });

  constructor(private companyApi: CompanyApiService, private fb: FormBuilder) {
    this.check()

  }

  check() {
    this.companyApi.getCompany().subscribe(value => {
      this.company = { name: value.name, phone: value.phone }
    })
  }

  onSubmit() {
    if (this.companyForm.valid) {
      const name = this.companyForm.value.name;
      const phone = this.companyForm.value.phone;
      this.companyApi.patchCompany({ name, phone }).subscribe(value => {
        this.companyForm.get("name")?.setValue("")
        this.companyForm.get("phone")?.setValue("")
        this.check()
      })
    }

  }
}
