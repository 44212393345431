
<app-modal [modalId]="'publicHolidays'" title="Import Publick Hollidays" [isForm]="true" >
    <form [formGroup]="yearAndCountryForm">
        <div style="display: flex;gap:20px">
        <div class="col-md-4 col-sm-12">
            <label for="country" class="form-label">Country</label>
            <select class="form-select" id="country" name="country" required formControlName="country"
                (change)="onValueChange()">
                <option [value]="country.countryCode" *ngFor="let country of countryList">
                    {{country.countryCode}} - {{country.name}}</option>
            </select>
        </div>

        <div class="col-md-3 col-sm-12 mb-3">
            <label for="year" class="form-label">Year</label>
            <input type="number" class="form-control" id="year" name="year" formControlName="year"
                (change)="onValueChange()">
        </div>
        <div>
        <button type="button" class="btn btn btn-primary" data-bs-toggle="modal"
        data-bs-target="#exampleModal" *ngIf="publicHolidays.length > 0">
        Next
    </button>
</div>
    </div>

    </form>
    
    <div>
        <div class="row">
            <div class="">
                <h2>Public Holidays</h2>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>LocalName</th>
                            <th>Date</th>
                            <th>Country</th>
                            <th>Regions</th>
                            <th>Global</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let holiday of publicHolidays">
                            <td>{{holiday.name}}</td>
                            <th>{{holiday.localName}}</th>
                            <td>{{holiday.date}}</td>
                            <td>{{holiday.country}}</td>
                            <td>
                                <ng-container *ngIf="holiday.regions && holiday.regions.length > 0; else showAll">
                                    <span class="badge bg-secondary"
                                        *ngFor="let region of holiday.regions">{{region.code}} -
                                        {{region.name}}</span>
                                </ng-container>
                                <ng-template #showAll>
                                    <span class="badge bg-primary">all</span>
                                </ng-template>
                            </td>
                            <td>
                                <span class="badge rounded-pill"
                                    [class]="holiday.global === true ? 'bg-primary':'bg-secondary'">{{
                                    holiday.global }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</app-modal>
<app-modal [modalId]="'listHolidayByOffice'" title="Import Publick Hollidays" >
    
</app-modal>

<div class="container-fluid mt-4 user-management clear-user-agent-styles">
    <app-management-header title="Public Holidays" actionButtonText="Import Public Holidays" modalId="publicHolidays">
    </app-management-header>
    <div class="row">
        <div class="col-md-6 col-6">
           
            <h2>Ofiices</h2>

            <table class="table table-bordered" *ngFor="let office of offices">
                <thead>
                    <tr>
                        <td colspan="7"> {{ office.name }} </td>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>LocalName</th>
                        <th>Date</th>
                        <th>Country</th>
                        <th>Regions</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>*</td>
                        <td><input class="form-control form-control-sm" type="text" placeholder="Name" #inp_name>
                        </td>
                        <th><input class="form-control form-control-sm" type="text" placeholder="localName"
                                #inp_localName></th>
                        <td><input class="form-control form-control-sm" type="date" placeholder="date" #inp_date>
                        </td>
                        <td>
                            <select class="form-control form-control-sm" name="country" #inp_country>
                                <option [value]="office.country" selected>
                                    {{getCountry(office.country).countryCode}} - {{getCountry(office.country).name}}
                                </option>
                                <option [value]="country.countryCode" *ngFor="let country of countryList">
                                    {{country.countryCode}} - {{country.name}}</option>
                            </select>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="text" placeholder="region" #inp_region>
                        </td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm"
                                (click)="add(office.id,inp_name,inp_localName,inp_date,inp_country,inp_region)">ADD</button>
                        </td>

                    </tr>
                    <tr>test</tr>
                    <tr *ngFor="let holiday of listHolidayByOffice(office.id)">
                        <td>{{holiday.id}}</td>
                        <td>{{holiday.name}}</td>
                        <th>{{holiday.localName}}</th>
                        <td>{{holiday.date}}</td>
                        <td>{{holiday.country}}</td>
                        <td> 
                            <div class="what">
                            <ng-container *ngIf="holiday.regions && holiday.regions.length > 0; else showAll">
                                <span class="badge bg-secondary" *ngFor="let region of holiday.regions">{{region.code}}
                                    -
                                    {{region.name}}</span>
                            </ng-container>
                        </div>
                            <ng-template #showAll>
                                <span class="badge bg-primary">all</span>
                            </ng-template>
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger btn-sm"
                                (click)="delete(holiday.id)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>





        </div>
        
    </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content" style="width:800px">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Seelct Office</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form (ngSubmit)="submitForm(importForm)" #importForm>
                <div class="modal-body" style="width:800px">
                    <div class="container">
                        <div class="row mb-3">
                            <select class="form-select form-select-sm" id="select_office" name="select_office">
                                <option selected disabled>Pick office</option>
                                <option [value]="office.id" *ngFor="let office of offices">
                                    Name: {{office.name}} | Country: {{office.country}}
                                </option>
                            </select>
                        </div>
                        <div class="row mb-3">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between"
                                    *ngFor="let item of publicHolidays; let i = index">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" [id]="'checkbox-' + i"
                                            [checked]="item.global" (change)="item.global = !item.global">
                                        <label class="form-check-label" [for]="'checkbox-' + i">{{ item.localName
                                            }}</label>
                                    </div>
                                    <div>{{ item.date }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-secondary" data-bs-dismiss="modal">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>