<div class="container mt-5">
    <div class="row">
      <div class="col-md-6 offset-md-3 text-center">
        <h1 class="display-4">Activate Email</h1>
        <div *ngIf="activationSuccess; else activationFail">
          <p class="text-success">Email activation successful!</p>
          <!-- Add any additional success message or redirection link here -->
        </div>
        <ng-template #activationFail>
            <p class="text-danger"> Email confirmation expired. </p>
          <!-- Add any additional error message or instructions here -->
        </ng-template>
      </div>
    </div>
  </div>