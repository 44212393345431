import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.css'
})
export class ModalComponent {
  @Input() modalTitle: string = 'Add';
  @Input() submitButtonText: string = 'Submit';
  @Input() modalId: string = '';
  @Input() contentStyle: any = '';
  @Output() onSubmit = new EventEmitter<void>();
  @Input() isForm: boolean = false;
  submit() {
    this.onSubmit.emit(); 
  }
}
