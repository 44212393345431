import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDTO } from '@model/userDTO';
import { RouterModule } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { MatIconModule } from '@angular/material/icon';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';
import { GetUsersComponent } from '@components/users/get-users/get-users.component';
import { AddUsersToOfficeComponent } from '../add-users-to-office/add-users-to-office.component';
@Component({
  selector: 'app-edit-office-users',
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    RouterModule,
    AlertComponent,
    GetUsersComponent,
    AddUsersToOfficeComponent,
  ],
  templateUrl: './edit-office-users.component.html',
  styleUrl: './edit-office-users.component.css',
})
export class EditOfficeUsersComponent {
  currentView: string = 'office-users';
  users: Array<UserDTO> = [];
  office: OfficeDTO | any;
  officeId: number = 0;
  constructor(
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private officeApi: OfficeApiService
  ) {}

  getOffice() {
    this.officeApi.getOfficeById(this.officeId).subscribe((data) => {
      console.log('officeId', this.officeId);
      console.log('value', data);
      this.office = data;
      this.users = this.office.users;
    });
  }
  isUserInOffice(userId: number): boolean {
    return this.office.users.some((user: UserDTO) => user.id === userId);
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.officeId = id;
    });
    this.getOffice();
  }

  removeUser(officeId: number, userId: number) {
    this.officeApi
      .deleteOfficeUser(officeId, { userIds: [userId] })
      .subscribe((value) => {
        this.alertService.sendAlertSuccess(
          'Member was removed from the office'
        );
        this.getOffice();
      });
  }
  setCurrentView(value: string) {
    this.currentView = value;
    this.getOffice();
  }

  
}
