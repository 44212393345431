<div class="modal fade" [attr.id]="modalId" tabindex="-1" aria-labelledby="editUserDataLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content" [ngStyle]="contentStyle">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="modal-body" [ngStyle]="contentStyle" >
        <div class="container">
          <ng-content></ng-content>
        </div>
      </div>
      <div *ngIf="!isForm">
        <div class="modal-footer">
          <button type="submit" class="btn btn-secondary" data-bs-dismiss="modal"
            (click)="submit()">{{submitButtonText}}</button>
        </div>
      </div>

    </div>

  </div>
</div>