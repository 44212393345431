<div class="container-fluid mt-4">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <form (ngSubmit)="onSubmit()" [formGroup]="companyForm">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control" id="name" name="name"  [placeholder]="company?.name" formControlName="name" >
          </div>
  
          <div class="mb-3">
            <label for="phone" class="form-label">Phone</label>
            <input type="text" class="form-control" id="phone" name="phone" [placeholder]="company?.phone" formControlName="phone" >
          </div>
          <button type="submit" class="btn btn-primary">Update</button>
        </form>
      </div>
    </div>
  </div>