import { CommonModule } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { Alert, AlertClassType, AlertService } from '../../service/alert/alert.service';



@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.css'
})
export class AlertComponent {

  alerts: Array<Alert> = [];

  constructor(private renderer: Renderer2, private alertService: AlertService) {

    this.alertService.getEvent().subscribe(value => {
      this.alerts.push(value);
      // Remove the alert after 3 seconds
      setTimeout(() => {
        this.removeAlert(value);
      }, 3000);
    });
  }

  removeAlert(alert: Alert) {
    const index = this.alerts.indexOf(alert);
    if (index !== -1) {
      this.alerts.splice(index, 1);
    }
  }


  toClass(alertClassType: AlertClassType):string{
    return alertClassType.toString()
  }
}
