import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { AddLeaveDTO } from '../model/addLeaveDTO';
import { UpdateLeaveDTO } from '../model/updateLeaveDTO';
import { LeaveStatusDTO } from '../model/leaveStatusDTO';
import { PageList } from '../model/pageList';
import { LeaveDTO } from '../model/leaveDTO';
import { LeaveQueryDTO } from '../model/leaveQueryDTO';
import { PageListAnnualLeaveReport } from '../model/pageListAttendance copy';
import { PageListLeave } from '../model/pageListLeave';
import { environment } from 'environments/environment';



@Injectable({
  providedIn: 'root'
})
export class LeaveApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getLeave(queryParams: LeaveQueryDTO): Observable<PageListLeave> {
    const url = this.baseUrl + '/leave';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

  postLeave( addLeaveDTO: AddLeaveDTO): Observable<LeaveDTO> {
    const url = this.baseUrl + '/leave';
    return this.http.post<any>(url, addLeaveDTO);
  }

  getLeaveById(id: number): Observable<LeaveDTO> {
    const url = this.baseUrl + '/leave/'+id+'';
    return this.http.get<any>(url );
  }

  deleteLeave( id: number ): Observable<void> {
    const url = this.baseUrl + '/leave/'+id+'';
    return this.http.delete<any>(url);
  }

  patchLeave(id: number,  updateLeaveDTO: UpdateLeaveDTO): Observable<LeaveDTO> {
    const url = this.baseUrl + '/leave/'+id+'';
    return this.http.patch<any>(url, updateLeaveDTO);
  }

  patchLeaveStatus(id: number,  leaveStatusDTO: LeaveStatusDTO): Observable<LeaveDTO> {
    const url = this.baseUrl + '/leave/status/'+id+'';
    return this.http.patch<any>(url, leaveStatusDTO);
  }

  getLeaveUser(queryParams: LeaveQueryDTO): Observable<PageList> {
    const url = this.baseUrl + '/leave/user';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

}
