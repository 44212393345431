import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';

@Component({
  selector: 'app-add-user-to-office',
  standalone: true,
  imports: [CommonModule, AlertComponent],
  templateUrl: './add-user-to-office.component.html',
  styleUrl: './add-user-to-office.component.css'
})
export class AddUserToOfficeComponent {
  offices: Array<OfficeDTO> = [];
  selectedOfficeId: number | undefined;
  @Input() selectedUserID: number | undefined;
  constructor(private officeApi:OfficeApiService, private alertService: AlertService){}
ngOnInit():void {
  this.getOffices()
}
getOffices() {
  this.officeApi.getOffice({ pageSize: 100 }).subscribe(value => {
    this.offices = value.content;
  })
}
onOfficeSelectionChange(event: Event) {
  const target = event.target as HTMLSelectElement;
  const value = target.value;
  if (value) {
    this.selectedOfficeId = parseInt(value, 10);
  } else {
    this.selectedOfficeId = undefined;
  }
}
addUserToOffice() {
  if (this.selectedOfficeId && this.selectedUserID !== undefined) {
    const userId = this.selectedUserID;
    const ids: number[] = [userId]; 

    this.officeApi.postOfficeUser(this.selectedOfficeId, { userIds: ids }).subscribe(() => {
      console.log('User Added to Office');
      this.alertService.sendAlertSuccess('The user was added to the office');
    });
  }
}
}
