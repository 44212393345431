import { Component, ElementRef, Renderer2, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UserDTO } from '@model/userDTO';
import { UsersApiService } from '@api/users.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MatIconModule, AlertComponent],
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {
  userForm: FormGroup;
  user: UserDTO | any = [];
  countryList: Array<string> = ['RO', 'DE'];
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(
    private fb: FormBuilder,
    private userApi: UsersApiService,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      roles: ['', Validators.required],
      confirmedEmail: [false],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      personalNumber: ['', Validators.required],
      socialSecurityNumber: ['', Validators.required],
      gender: ['', Validators.required],
      birthday: ['', Validators.required],
      privateEmail: ['', Validators.email],
      maritalStatus: [''],
      parentalStatus: [''],
      singleParent: [false],
      highestProfessionalEducation: [''],
      schoolLeavingCertificate: [''],
      occupation: [''],
      startDate: ['', Validators.required],
      weeklyWorkingHours: [0, Validators.required],
      vacationEntitlement: [0, Validators.required],
      contractType: ['', Validators.required],
      severeDisability: [''],
      employmentStatus: [''],
      typeOfWorkplace: [''],
      iban: ['', Validators.pattern(/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/)],
      address: this.fb.array([this.createAddressGroup()]),
      phones: this.fb.array([this.createPhoneGroup()]),
      children: this.fb.array([this.createChildrenGroup()]),
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Update User Info');
    this.route.params.subscribe((params) => {
      const userId = params['id'];
      this.getUserProfile(userId);
    });
  }

  createAddressGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      type: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
  }

  createPhoneGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      contact: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      type: ['', Validators.required],
    });
  }

  createChildrenGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      name: ['', Validators.required],
      birthday: ['', Validators.required],
    });
  }

  get addressForms() {
    return this.userForm.get('address') as FormArray;
  }

  get phoneForms() {
    return this.userForm.get('phones') as FormArray;
  }

  get childrenForms() {
    return this.userForm.get('children') as FormArray;
  }

  addAddress() {
    this.addressForms.push(this.createAddressGroup());
  }

  addPhone() {
    this.phoneForms.push(this.createPhoneGroup());
  }

  addChild() {
    this.childrenForms.push(this.createChildrenGroup());
  }

  removeAddress(index: number) {
    this.addressForms.removeAt(index);
  }

  removePhone(index: number) {
    this.phoneForms.removeAt(index);
  }

  removeChild(index: number) {
    this.childrenForms.removeAt(index);
  }

  getUserProfile(id: number) {
    this.userApi.getUsersProfile(id).subscribe((value) => {
      this.user = value;
      console.log('User data from API:', this.user);

      // Populate the form with the user data
      this.userForm.patchValue(this.user);
      this.userForm.setControl(
        'address',
        this.fb.array(this.user.address.map((addr: any) => this.fb.group(addr)))
      );
      this.userForm.setControl(
        'phones',
        this.fb.array(
          this.user.phones.map((phone: any) => this.fb.group(phone))
        )
      );
      this.userForm.setControl(
        'children',
        this.fb.array(
          this.user.children.map((child: any) => this.fb.group(child))
        )
      );

      // Setting roles and contractType explicitly
      const rolesControl = this.userForm.get('roles');
      if (rolesControl) {
        rolesControl.setValue(this.user.roles);
      }

      const contractTypeControl = this.userForm.get('contractType');
      if (contractTypeControl) {
        contractTypeControl.setValue(this.user.contractType);
      }
    });
  }

  updateUser() {
    if (this.userForm.valid) {
      console.log('this.userForm.value', this.userForm.value);
      this.userApi
        .patchUsers(this.user.id, this.userForm.value)
        .subscribe(() => {
          let userName =
            this.userForm.value.firstName + ' ' + this.userForm.value.lastName;
          this.alertService.sendAlertSuccess(
            `Member ${userName} was updated succesfully`
          );
          this.router.navigate(['management/users']);
        });
    } else {
      console.log('Form is invalid', this.userForm.errors);
      this.scrollToFirstError();
      this.userForm.markAllAsTouched();
      this.alertService.sendAlertError('Some fields in form are not valid');
    }
  }

  cancelSubmit() {
    this.router.navigate(['management/users']);
  }

  onSubmit() {
    const userFormValue = this.userForm.value;

    if (!userFormValue.password) {
      userFormValue.password = null;
    }
    this.updateUser();
  }

  private scrollToFirstError() {
    const firstErrorElement =
      this.el.nativeElement.querySelector('.text-danger');
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
