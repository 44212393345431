import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { UserDTO } from '@model/userDTO';
import { MonthInfo } from '@model/monthInfo';
import { CalendarApiService } from '@api/calendar.service';
interface Day {
  calendarList: any[]; // Aici specificiți tipurile de date pentru 'calendarList'
  // alte proprietăți ale 'day' dacă există
}
@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent {
  isWeekend(dayName: string): any {
    return dayName === "Sa" || dayName === "Su"
  }

  onChange() {
    this.setMonth()
  }

  month: Array<{ day: number, name: string }> = []
  events: Array<MonthInfo> = [];
  eventsForm: FormGroup = this.fb.group({
    year: [moment().year()],
    month: [Number.parseInt(moment().format("MM"))]
  });

  constructor(private fb: FormBuilder, private calendarApi: CalendarApiService,) {
    this.setMonth()


  }
 
  
 
  hasEvents(user: any): boolean {
    console.log('hasEvent', user.monthEventList);
    console.log('hasEvent', user);
    // is user has minimum 1 day off in this office 
    return user.monthEventList.some((day: Day) => day.calendarList.length > 0);
  }
  
  
  setMonth() {
    this.month = [];
    const year = this.eventsForm.value.year as number;
    let month = this.eventsForm.value.month as number;

    // Ensure month is within a valid range (1 to 12)
    if (month < 1) {
      month = 1;
      this.eventsForm.patchValue({ month });
    } else if (month > 12) {
      month = 12;
      this.eventsForm.patchValue({ month });
    }

    const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();

    for (let i = 1; i <= daysInMonth; i++) {
      // Ensure day is within a valid range (1 to daysInMonth)
      if (i < 1 || i > daysInMonth) {
        continue; // Skip invalid day values
      }

      const currentDay = moment(`${year}-${month}-${i}`, "YYYY-MM-DD");
      this.month.push({ day: i, name: currentDay.format("dddd").substring(0, 2) });
    }
    this.calendarApi.getCalendarMonthInfo({ month, year }).subscribe(value => {
      this.events = value;
    })
  }

  getMonthInfo(userId: number) {
    return
  }

  getEvents(day: number, user: UserDTO): any {
    const year = this.eventsForm.value.year as number;
    let month = this.eventsForm.value.month as number;

    // Ensure month is within a valid range (1 to 12)
    if (month < 1) {
      month = 1;
      this.eventsForm.patchValue({ month });
    } else if (month > 12) {
      month = 12;
      this.eventsForm.patchValue({ month });
    }

    const currentDay = moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format("YYYY-MM-DD")
    const monthEventList = this.events.filter(o => o.user.id === user.id)[0].monthEventList
    const eventList = monthEventList.filter(o => o.date === currentDay)
    if (eventList[0] !== undefined) {
      return eventList[0].calendarList
    } else {
      return []
    }

  }
}
