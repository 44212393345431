<footer class="footer mt-auto py-3 bg-light">
  <div class="container text-center">
    <div class="mt-2 mb-2">
      <a href="https://www.imolimo.ro/politica-de-confidentialitate">Politica de confidențialitate</a>
      <span class="mx-2">|</span>
      <a href="https://www.imolimo.ro/termeni-si-conditii">Termeni și condiții</a>
      <span class="mx-2">|</span>
      <a href="https://www.anpc.gov.ro/">ANPC</a>
    </div>
    <div>
    <span>&copy; 2024 Timo. Toate drepturile rezervate.</span>
  </div>
  </div>
</footer>
