import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { CreateOfficeDTO } from '../model/createOfficeDTO';
import { UserToOfficeDTO } from '../model/userToOfficeDTO';
import { UpdateOfficeDTO } from '../model/updateOfficeDTO';
import { PageList } from '../model/pageList';
import { OfficeDTO } from '../model/officeDTO';
import { OfficeQueryDTO } from '../model/officeQueryDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfficeApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getOffice(queryParams: OfficeQueryDTO): Observable<PageList> {
    const url = this.baseUrl + '/office';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

  postOffice( createOfficeDTO: CreateOfficeDTO): Observable<OfficeDTO> {
    const url = this.baseUrl + '/office';
    return this.http.post<any>(url, createOfficeDTO);
  }

  postOfficeUser(officeId: number,  userToOfficeDTO: UserToOfficeDTO): Observable<OfficeDTO> {
    const url = this.baseUrl + '/office/'+officeId+'/user';
    return this.http.post<any>(url, userToOfficeDTO);
  }

  deleteOfficeUser(officeId: number,  userToOfficeDTO: UserToOfficeDTO): Observable<OfficeDTO> {
    const url = this.baseUrl + '/office/'+officeId+'/user';
    return this.http.delete<any>(url, {body:userToOfficeDTO});
  }

  getOfficeById(id: number): Observable<OfficeDTO> {
    const url = this.baseUrl + '/office/'+id+'';
    return this.http.get<any>(url );
  }

  deleteOffice( id: number ): Observable<void> {
    const url = this.baseUrl + '/office/'+id+'';
    return this.http.delete<any>(url);
  }

  patchOffice(id: number,  updateOfficeDTO: UpdateOfficeDTO): Observable<OfficeDTO> {
    const url = this.baseUrl + '/office/'+id+'';
    return this.http.patch<any>(url, updateOfficeDTO);
  }

}
