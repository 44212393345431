import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MarkAttendanceDTO } from '../model/markAttendanceDTO';
import { UpdateAttendanceDTO } from '../model/updateAttendanceDTO';
import { AttendanceDTO } from '../model/attendanceDTO';
import { QueryPaginationDTO } from '../model/queryPaginationDTO';
import { PageListAttendance } from '../model/pageListAttendance';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttendanceApiService {
  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getAttendance(
    queryParams: QueryPaginationDTO
  ): Observable<PageListAttendance> {
    const url = this.baseUrl + '/attendance';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }
  getUsersAttendance(
    queryParams: QueryPaginationDTO
  ): Observable<PageListAttendance> {
    const url = this.baseUrl + '/attendance/user';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }
  postAttendance(
    markAttendanceDTO: MarkAttendanceDTO
  ): Observable<AttendanceDTO> {
    const url = this.baseUrl + '/attendance';
    return this.http.post<any>(url, markAttendanceDTO);
  }

  deleteAttendance(id: number): Observable<void> {
    const url = this.baseUrl + '/attendance/' + id + '';
    return this.http.delete<any>(url);
  }

  patchAttendance(
    id: number,
    updateAttendanceDTO: UpdateAttendanceDTO
  ): Observable<AttendanceDTO> {
    const url = this.baseUrl + '/attendance/' + id + '';
    return this.http.patch<any>(url, updateAttendanceDTO);
  }
}
