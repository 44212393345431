import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardManagerApiService } from '@api/dashboard-manager.service';
import { DashboardManagerDTO } from '@model/dashboardManagerDTO';
import {MatIconModule} from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.css'],
  standalone: true,
  imports:[CommonModule, RouterModule, MatIconModule]
})
export class ManagerDashboardComponent implements OnInit {
  dashboardData: DashboardManagerDTO | null = null;
  loading: boolean = true;
  error: string | null = null;

  constructor(private dashboardManagerApi: DashboardManagerApiService,
    private pageTitle: Title
  ) { }

  ngOnInit(): void {
    this.loadDashboardData();
    this.pageTitle.setTitle('Timo - Manager Dashboard');
  }

  loadDashboardData() {
    this.dashboardManagerApi.getDashboardData().subscribe(
      (data: DashboardManagerDTO) => {
        this.dashboardData = data;
        this.loading = false;
        console.log('dashboardData.employersBirthday', this.dashboardData)
        console.log('dashboardData.employersBirthday', this.dashboardData.employersBirthday)
      },
      (error: any) => {
        this.error = "Error loading dashboard data.";
        this.loading = false;
      }
    );
  }
}
