import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LeaveTypeDTO } from '@model/leaveTypeDTO';
import { LeaveDTO } from '@model/leaveDTO';
import { LeaveApiService } from '@api/leave.service';
import { LeaveTypeApiService } from '@api/leave-type.service';
import { AnnualReportsApiService } from '@api/annual-reports.service';
import moment from 'moment';
import { AnnualLeaveReportDTO } from '@model/annualLeaveReportDTO';
import { ManagementHeaderComponent } from '@components/menu/management-header/management-header.component';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { AlertService } from '@alert/alert.service';
import { catchError } from 'rxjs';
@Component({
  selector: 'app-leave',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ManagementHeaderComponent, ModalComponent],
  templateUrl: './leave.component.html',
  styleUrl: './leave.component.css'
})
export class LeaveComponent {

  leaveForm: FormGroup = this.fb.group({
    leaveTypeId: ['', Validators.required],
    dateFrom: ['', Validators.required],
    dateTo: ['',],
    comment: ['',],
    halfDay: [false]
  });

  selectedLeaveType: LeaveTypeDTO | undefined
  leaveTypeList: Array<LeaveTypeDTO> = []
  leaveList: Array<LeaveDTO> = []
  userYearReports: Array<AnnualLeaveReportDTO> = [];

  constructor(
    private leaveApi: LeaveApiService,
    private leaveTypeApi: LeaveTypeApiService,
    private annualApi: AnnualReportsApiService,
    private alert: AlertService,
    private fb: FormBuilder) {
    this.updateLeaveAndLeaveType()
  }
  onSubmit() {
    if (this.leaveForm.valid) {

      const dateFrom = this.leaveForm.value.dateFrom
      var dateTo = this.leaveForm.value.dateTo
      if (this.leaveForm.value.halfDay) {
        dateTo = this.leaveForm.value.dateFrom
      }
      this.leaveApi.postLeave({
        leaveTypeId: this.leaveForm.value.leaveTypeId,
        dateFrom,
        dateTo,
        comment: this.leaveForm.value.comment,
        halfDay: this.leaveForm.value.halfDay
      }).pipe(catchError(error => {
        this.alert.handleError(error, cb => {
          this.alert.sendAlertError(cb.message);
        });
        return [];
      })).subscribe(value => {
        this.updateLeaveAndLeaveType()
      })
    }
  }
  changeStatus() {
    let id: number = this.leaveForm.value.leaveTypeId as number;
    this.selectedLeaveType = this.leaveTypeList.filter(el => el.id == id)[0]
    if (this.selectedLeaveType !== undefined && this.selectedLeaveType.halfDay) {
      this.leaveForm.get('halfDay')?.enable();
    } else {
      this.leaveForm.get('halfDay')?.disable();
      this.leaveForm.get('halfDay')?.setValue(false)
    }
    if (this.leaveForm.value.halfDay) {
      this.leaveForm.get('dateTo')?.disable();
      this.leaveForm.get('dateTo')?.setValue(this.leaveForm.value.dateFrom)
    } else {
      this.leaveForm.get('dateTo')?.enable()
    }
  }

  updateLeaveAndLeaveType() {
    this.leaveTypeApi.getLeaveType({ pageSize: 100 }).subscribe(value => {
      this.leaveTypeList = value.content;
    })
    this.leaveApi.getLeaveUser({ pageSize: 100 }).subscribe(value => {
      console.log(value.content)
      this.leaveList = value.content;
    })
    this.annualApi.getAnnualReportsUser({ year: moment().year() }).subscribe(value => {
      this.userYearReports = value.content;
    })
  }
}
