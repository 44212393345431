<div *ngIf="isNewMember; else existingMemberTemplate">
  <div class="alert alert-success">
    <h4>Congratulations!</h4>
    <p>The user <strong>{{ memberName }}</strong> has been successfully created, but doesn't have an office associated yet.</p>
  </div>
</div>

<ng-template #existingMemberTemplate>
  <div class="alert alert-warning">
    <h4>Add user to office</h4>
    <p>The user <strong>{{ memberName }}</strong> is an existing member who doesn't have an associated office. Please add this user to an office.</p>
  </div>
</ng-template>
<div> <h2>Select an Office</h2>
  <div style="display: flex; margin-top: 40px;">
    <div style="width: 400px;">
     <select class="form-control" [value]="selectedOfficeId" (change)="onOfficeSelectionChange($event)">
      <option *ngFor="let office of offices" [value]="office.id">{{ office.name }}</option>
    </select>
  </div>
    <button class="btn btn-primary" (click)="addUserToOffice()">Add User to Office</button>
  </div>
  </div>
  
  <app-alert></app-alert>
