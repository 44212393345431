import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-label',
  standalone: true,
  imports: [CommonModule],  // Importă CommonModule aici
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent {
  @Input() forAttribute: string = '';
  @Input() labelText: string = '';
  @Input() isRequired: boolean = false;
}
