import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { ResetPasswordDTO } from '../model/resetPasswordDTO';
import { ResendEmailDTO } from '../model/resendEmailDTO';
import { RegisterDTO } from '../model/registerDTO';
import { LostPasswordDTO } from '../model/lostPasswordDTO';
import { LoginDTO } from '../model/loginDTO';
import { ResponseTokenDTO } from '../model/responseTokenDTO';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  postAuthToken( refreshToken: string ): Observable<ResponseTokenDTO> {
    const url = this.baseUrl + '/auth/token';
    return this.http.post<any>(url, null, { headers: { 'refresh-token': refreshToken } });
  }

  postAuthReset_password(tokenId: string,  resetPasswordDTO: ResetPasswordDTO): Observable<void> {
    const url = this.baseUrl + '/auth/reset_password/'+tokenId+'';
    return this.http.post<any>(url, resetPasswordDTO);
  }

  postAuthResend_confirmation( resendEmailDTO: ResendEmailDTO): Observable<void> {
    const url = this.baseUrl + '/auth/resend_confirmation';
    return this.http.post<any>(url, resendEmailDTO);
  }

  postAuthRegister( registerDTO: RegisterDTO): Observable<void> {
    const url = this.baseUrl + '/auth/register';
    return this.http.post<any>(url, registerDTO);
  }

  postAuthLost_password( lostPasswordDTO: LostPasswordDTO): Observable<void> {
    const url = this.baseUrl + '/auth/lost_password';
    return this.http.post<any>(url, lostPasswordDTO);
  }

  postAuthLogin( loginDTO: LoginDTO): Observable<ResponseTokenDTO> {
    const url = this.baseUrl + '/auth/login';
    return this.http.post<any>(url, loginDTO);
  }

  postAuthConfirmAction( tokenId: string ): Observable<void> {
    const url = this.baseUrl + '/auth/confirm/action/'+tokenId+'';
    return this.http.post<any>(url,null);
  }

}
