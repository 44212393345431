import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserDTO } from '@model/userDTO';
import { UsersApiService } from '@api/users.service';
import { UpdateUserComponent } from '@components/users/user/update-user/update-user.component';
import { ActionMenuComponent } from '@components/menu/action-menu/action-menu.component';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-add-users-to-office',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ActionMenuComponent,
    ModalComponent,
    MatIconModule,
    UpdateUserComponent,
    AlertComponent,
  ],
  templateUrl: './add-users-to-office.component.html',
  styleUrl: './add-users-to-office.component.css',
})
export class AddUsersToOfficeComponent {
  @Output() changeView = new EventEmitter<string>();
  @Output() selectedUser = new EventEmitter<UserDTO>();
  @Input() officeId: number = 0;
  @Input() usersInOffice: Array<UserDTO | any> = [];
  users: Array<UserDTO | any> = [];
  currentUser: UserDTO | any;
  selectedOfficeId: number = 0;
  selectedUserID: number = 0;
  searchTerm: string = '';
  filteredUsers: Array<UserDTO | any> = [];
  showCreateUserMessage: boolean = false;
  constructor(
    private fb: FormBuilder,
    private userApi: UsersApiService,
    private titleService: Title,
    private officeApi: OfficeApiService,
    private alertService: AlertService,
    private router: Router
  ) {
    // this.getUsers()
  }

  ngOnInit(): void {
    console.log('Component is initialized, making an API call...');
    this.titleService.setTitle('User Management');
    this.getUsers();
    console.log('currentUser', this.currentUser);
  }

  getUsers() {
    this.userApi
      .getUsersList({
        page: 0,
        pageSize: 100,
      })
      .subscribe((value) => {
        console.log(value);
        this.users = value.content;
        this.filteredUsers = this.users;
      });
  }
  // filterUsers() {
  //   this.filteredUsers = this.users.filter((user: UserDTO) => {
  //     const fullName = user.firstName + ' ' + user.lastName;
  //     return fullName.toLowerCase().includes(this.searchTerm.toLowerCase());
  //   });
  // }
  filterUsers() {
    this.showCreateUserMessage = true;
    this.userApi
      .getUsersList({
        page: 0,
        pageSize: 100,
        searchText: this.searchTerm,
      })
      .subscribe((value) => {
        console.log(value);
        this.filteredUsers = value.content;
      });
  }
  isUserInOffice(userId: number): boolean {
    return this.usersInOffice.some((user: UserDTO) => user.id === userId);
  }
  removeUser(officeId: number, userId: number) {
    this.officeApi
      .deleteOfficeUser(officeId, { userIds: [userId] })
      .subscribe(() => {
        this.alertService.sendAlertSuccess(
          'Member was removed from the office'
        );
        this.usersInOffice = this.usersInOffice.filter(
          (user: UserDTO) => user.id !== userId
        );
        this.getUsers();
      });
  }

  addUserToOffice(officeId: number, selectedUserId: number) {
    const ids: number[] = [selectedUserId];
    this.officeApi.postOfficeUser(officeId, { userIds: ids }).subscribe(() => {
      console.log('User Added to Office');
      this.alertService.sendAlertSuccess('The user was added to the office');
      this.usersInOffice.push(
        this.filteredUsers.find((user: UserDTO) => user.id === selectedUserId)
      );
      this.getUsers();
    });
  }
  createNewUser(officeId: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: { key: 'office', id: officeId }
    };
    this.router.navigate(['/management/users/user/create'], navigationExtras);
  }
}
