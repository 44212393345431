<div class="d-flex flex-row mb-5 management-header" style="border-bottom: 1px solid #d2cdcd;
padding-bottom: 1rem;">
    <h2 routerLink="/management/offices">
        <span style="cursor: pointer;color: #0d6efd;">Office Management </span>
    </h2>
    <button *ngIf="currentView === 'office-users'" class="ms-auto btn btn-sm btn-primary ps-4 py-2" (click)=" setCurrentView('get-users')">
        <span style="padding-right: 8px;">Add Member to office</span>
      </button>
      <button *ngIf="currentView === 'get-users'" class="ms-auto btn btn-sm btn-primary ps-4 py-2" (click)=" setCurrentView('office-users')">
        <span style="padding-right: 8px;">Show office Users</span>
      </button>
</div>

<div>Selected Office <strong>  {{office.name}} </strong> user management</div>

    <app-add-users-to-office *ngIf="currentView === 'get-users'" [officeId]="officeId" [usersInOffice]="users" 
    (changeView)="setCurrentView($event)"></app-add-users-to-office>
<div *ngIf="currentView === 'office-users'" class="container-fluid mt-4 user-management clear-user-agent-styles">
    <ng-container *ngIf="users && office.users.length > 0; else noUsers">
        <table class="table table-bordered">
            <thead class="user-list">
                <tr>
                    <th class="role-bar"></th>
                    <th class="user-name">Name</th>
                    <th class="conector"></th>
                    <th class="user-email">Email</th>
                    <th>Confirmed Email</th>
                    <th><span class="role">Roles</span></th>
                    <th class="conector"></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let user of office.users">
                    <td class="role-bar" [ngClass]="user.roles"></td>
                    <td class="user-name">{{user.firstName}} {{user.lastName}}</td>
                    <th class="conector">
                        <div class="line"></div>
                    </th>
                    <td class="user-email">{{user.email}}</td>
                    <td>{{user.confirmedEmail}}</td>
                    <td class="user-role"><span [ngClass]="user.roles">{{user.roles}}</span></td>
                    <th class="conector">
                        <div class="line"></div>
                    </th>
                    <td class="text-center">
                        <button class="btn hover-primary px-2" type="button" (click)="removeUser(office.id, user.id)">
                            <mat-icon class="material-icons-outlined">person_remove</mat-icon>
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </ng-container>
    <ng-template #noUsers>
        <div class="alert alert-info" role="alert">
            <h2>Office has no members</h2>
        </div>
    </ng-template>
</div>