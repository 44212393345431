<div class="container-fluid mt-4 user-management clear-user-agent-styles">
  <input type="text" class="form-control mb-3" [(ngModel)]="searchTerm" (input)="filterUsers()"
    placeholder="Search by name">
  <table class="table table-bordered">
    <thead class="user-list">
      <tr>
        <!-- <th>ID</th> -->
        <th class="role-bar"></th>
        <th class="user-name">Name</th>
        <th class="conector"></th>
        <!-- <th>Password</th> -->
        <th class="user-email">Email</th>
        <th>Confirmed Email</th>
        <th><span class="role">Roles</span></th>
        <th class="conector"></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of filteredUsers">
        <td class="role-bar" [ngClass]="user.roles"></td>
        <td class="user-name">{{user.firstName}} {{user.lastName}}</td>
        <th class="conector">
          <div class="line"></div>
        </th>
        <td class="user-email">{{user.email}}</td>
        <td>{{user.confirmedEmail}}</td>
        <td class="user-role"><span [ngClass]="user.roles">{{user.roles}} </span></td>
        <th class="conector">
          <div class="line"></div>
        </th>

        <td class="text-center">
          <ng-container *ngIf="!isUserInOffice(user.id)">
            <button class="btn hover-primary px-2" type="button" (click)="addUserToOffice(officeId,user.id)">
              <mat-icon class="material-icons-outlined">person_add_alt</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="isUserInOffice(user.id)">
            <button class="btn hover-primary px-2" type="button" (click)="removeUser(officeId,user.id)">
              <mat-icon class="material-icons-outlined">person_remove</mat-icon>
            </button>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="filteredUsers.length === 0">
    <div class="container" *ngIf="showCreateUserMessage">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="text-center mt-5">
            <h2>No users found</h2>
            <p>There are no users in the database that match your search. You can create a new user.</p>
            <button class="btn btn-primary" (click)="createNewUser(officeId)">Create New User</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>