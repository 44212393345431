
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { CountryDTO } from '@model/countryDTO';
import { CountryApiService } from '@api/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';
@Component({
  selector: 'app-create-office',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule,ReactiveFormsModule,AlertComponent],
  templateUrl: './create-office.component.html',
  styleUrl: './create-office.component.css'
})
export class CreateOfficeComponent {
  office: OfficeDTO | any;
  countries: CountryDTO | any;
  createOfficeForm: FormGroup = this.fb.group({
    name: '',
    country: '',
    description: '',
  });
  formData = {
    name: '',
    country: '',
    description: '',
   
  };
  constructor(
    private fb: FormBuilder,
    private officeApi: OfficeApiService,
    private route: ActivatedRoute,
    private router: Router ,
    private countryApi: CountryApiService,
    private alertService: AlertService
  ) {
    
  }

  createOffice() {
     this.officeApi.postOffice(this.createOfficeForm.value).subscribe(()=>{
      this.alertService.sendAlertSuccess('The office was created succesfuly');
      this.router.navigate(['/management/offices']);
    })
  }
  getCountries() {
    this.countryApi.getCountryList().subscribe((value) => {
      this.countries = value;
    });
  }
  ngOnInit(): void {
    console.log("this.createOfficeForm.value", this.createOfficeForm.value)
    this.getCountries();
  }
  onSubmit(){
    this.createOffice()
    
  }
}





