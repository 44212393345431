import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AlertService {


    private _alertEvent: EventEmitter<Alert> = new EventEmitter<Alert>();


    getEvent(): EventEmitter<Alert> {
        return this._alertEvent;
    }

    sendAlert(alert: Alert) {
        this._alertEvent.emit(alert);
    }

    sendAlertInfo(message: string) {
        this._alertEvent.emit({ message, type: AlertType.Info, classType: AlertClassType.Info });
    }

    sendAlertSuccess(message: string) {
        this._alertEvent.emit({ message, type: AlertType.Success, classType: AlertClassType.Success });
    }

    sendAlertWarning(message: string) {
        this._alertEvent.emit({ message, type: AlertType.Warning, classType: AlertClassType.Warning });
    }

    sendAlertError(message: string) {
        this._alertEvent.emit({ message, type: AlertType.Error, classType: AlertClassType.Error });
    }


    handleError(error: HttpErrorResponse, cb:ErrorCallback): Observable<never> {
        console.log(error)
        var status = Math.round(error.status / 100)
        var message = error.message;
        
        if(error.error !== null){
            console.log(error.error['errorMessage'])
            message = error.error['errorMessage']
        }
        switch (status) {
            case 4:
            case 5:
                cb({ statusCode: error.status, message })
                break;
        }
        throw ('Something bad happened; please try again later.');
    }

}

export interface Alert {
    message: string;
    type: AlertType;
    classType: AlertClassType
}

export enum AlertType {
    Success = 'Success',
    Error = 'Error',
    Warning = 'Warning',
    Info = 'Info'
}

export enum AlertClassType {
    Success = 'alert-success',
    Error = 'alert-danger',
    Warning = 'alert-warning',
    Info = 'alert-info'
}

export interface ErrorCallback {
    (errorDetails: { statusCode: number; message: string }): void;
}