import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { MonthInfo } from '../model/monthInfo';
import { CalendarInfoQueryDTO } from '../model/calendarInfoQueryDTO';
import { environment } from 'environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class CalendarApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getCalendarMonthInfo(queryParams: CalendarInfoQueryDTO): Observable<Array<MonthInfo>> {
    const url = this.baseUrl + '/calendar/month-info';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

}
