<div> <h2>Select an Office</h2>
<div style="display: flex; margin-top: 40px;">
  <div style="width: 400px;">
   <select class="form-control" [value]="selectedOfficeId" (change)="onOfficeSelectionChange($event)">
    <option *ngFor="let office of offices" [value]="office.id">{{ office.name }}</option>
  </select>
</div>
  <button class="btn btn-primary" (click)="addUserToOffice()">Add User to Office</button>
</div>
</div>

<app-alert></app-alert>