import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnnualLeaveReportDTO } from '@model/annualLeaveReportDTO';
import { UserDTO } from '@model/userDTO';
import moment from 'moment';
import { AnnualReportsApiService } from '@api/annual-reports.service';
import { UsersApiService } from '@api/users.service';
import { DownloadApiService } from '@api/download.service';

@Component({
  selector: 'app-annual-leave-report',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule],
  templateUrl: './annual-leave-report.component.html',
  styleUrl: './annual-leave-report.component.css'
})
export class AnnualLeaveReportComponent {
  download(userId: number) {
    const year = this.yearForm.value.year as number
    this.downloadApi.getDownloadYearReports({ fileType: "CSV", year, userId }).subscribe((response: HttpResponse<Blob>) => {
      console.log(response.headers)
      const contentDisposition = response.headers.get('Content-Disposition');
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);

      if (fileNameMatch && fileNameMatch[1]) {
        const fileName = fileNameMatch[1];
        const blob = new Blob([response.body as Blob], { type: 'text/csv' });

        // Use a library or create a download link to initiate the download
        // For example:
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle error: Unable to determine filename
        console.error('Unable to determine filename from Content-Disposition header.');
      }
    })
  }

  yearReport: Array<AnnualLeaveReportDTO> = []
  users: Array<UserDTO> = []
  yearForm: FormGroup = this.fb.group({
    year: [moment().year()],
  });


  constructor(private fb: FormBuilder,
    private userApi: UsersApiService,
    private downloadApi: DownloadApiService,
    private annualReportsApi: AnnualReportsApiService) {

    this.getYearReport()
  }
  onSubmit() {
    const year = this.yearForm.value.year as number

    this.downloadApi.getDownloadYearReports({ fileType: "CSV", year }).subscribe((response: HttpResponse<Blob>) => {

      const contentDisposition = response.headers.get('Content-Disposition');
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);

      if (fileNameMatch && fileNameMatch[1]) {
        const fileName = fileNameMatch[1];
        const blob = new Blob([response.body as Blob], { type: 'text/csv' });

        // Use a library or create a download link to initiate the download
        // For example:
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle error: Unable to determine filename
        console.error('Unable to determine filename from Content-Disposition header.');
      }
    })
  }





  yearReportByUser(id: number): Array<AnnualLeaveReportDTO> {
    return this.yearReport?.filter(value => value.user.id === id)
  }
  onChangeYear() {
    const year = this.yearForm.value.year as number
    this.annualReportsApi.getAnnualReports({ year: year }).subscribe(value => {
      this.yearReport = value.content.sort((a, b) => a.user.name.localeCompare(b.user.name))
    })
  }
  getYearReport() {

    this.userApi.getUsersList({ pageSize: 100 }).subscribe(u => {
      this.users = u.content;
    })
    const year = this.yearForm.value.year as number
    this.annualReportsApi.getAnnualReports({ year: year }).subscribe(value => {
      console.log(value)
      this.yearReport = value.content.sort((a, b) => a.user.name.localeCompare(b.user.name))
    })
  }

}
