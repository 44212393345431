<div class="app-container">
  <app-sidebar-menu></app-sidebar-menu>
  <div class="main-container">
    <app-menu></app-menu>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
  <app-alert></app-alert>
</div>
<app-footer></app-footer>