import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateUserDTO } from '../model/createUserDTO';
import { UpdateUserDTO } from '../model/updateUserDTO';
import { UserDTO } from '../model/userDTO';
import { PageList } from '../model/pageList';
import { QueryPaginationDTO } from '../model/queryPaginationDTO';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  postUsers(createUserDTO: CreateUserDTO): Observable<UserDTO> {
    const url = this.baseUrl + '/users';
    return this.http.post<any>(url, createUserDTO);
  }

  getUsers(id: number): Observable<UserDTO> {
    const url = this.baseUrl + '/users/' + id + '';
    return this.http.get<any>(url);
  }
  getUsersProfile(id: number): Observable<UserDTO> {
    const url = this.baseUrl + '/users/' + id + '/profile';
    return this.http.get<any>(url);
  }
  deleteUsers(id: number): Observable<void> {
    const url = this.baseUrl + '/users/' + id + '';
    return this.http.delete<any>(url);
  }

  patchUsers(id: number, updateUserDTO: UpdateUserDTO): Observable<UserDTO> {
    const url = this.baseUrl + '/users/' + id + '';
    return this.http.patch<any>(url, updateUserDTO);
  }

  getUsersList(queryParams: QueryPaginationDTO): Observable<PageList> {
    const url = this.baseUrl + '/users';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }
}
