import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthApiService } from '@api/auth.service';
import { AlertService } from '@alert/alert.service';
import { catchError } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-activate-email',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './activate-email.component.html',
  styleUrl: './activate-email.component.css'
})
export class ActivateEmailComponent implements OnInit {
resendConfirmation() {
throw new Error('Method not implemented.');
}
  activationSuccess = false;
  constructor(
    private route: ActivatedRoute,
    private authApi: AuthApiService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.activateEmail();
  }

  activateEmail(): void {
    this.route.params.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.authApi.postAuthConfirmAction(token)
          .pipe(catchError(error => this.alert.handleError(error, cb => {
            this.activationSuccess = false;
            this.alert.sendAlertError(cb.message)
          })))
          .subscribe(
            () => {
              this.activationSuccess = true;
              this.alert.sendAlertSuccess("Email confirmed");
            }
          );
      }
    });
  }

}
