import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { CreateLeaveTypeDTO } from '../model/createLeaveTypeDTO';
import { UpdateLeaveTypeDTO } from '../model/updateLeaveTypeDTO';
import { PageList } from '../model/pageList';
import { LeaveTypeDTO } from '../model/leaveTypeDTO';
import { LeaveTypeQueryDTO } from '../model/leaveTypeQueryDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaveTypeApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getLeaveType(queryParams: LeaveTypeQueryDTO): Observable<PageList> {
    const url = this.baseUrl + '/leave-type';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

  postLeaveType( createLeaveTypeDTO: CreateLeaveTypeDTO): Observable<LeaveTypeDTO> {
    const url = this.baseUrl + '/leave-type';
    return this.http.post<any>(url, createLeaveTypeDTO);
  }

  getLeaveTypeById(id: number): Observable<LeaveTypeDTO> {
    const url = this.baseUrl + '/leave-type/'+id+'';
    return this.http.get<any>(url );
  }

  deleteLeaveType( id: number ): Observable<void> {
    const url = this.baseUrl + '/leave-type/'+id+'';
    return this.http.delete<any>(url);
  }

  patchLeaveType(id: number,  updateLeaveTypeDTO: UpdateLeaveTypeDTO): Observable<LeaveTypeDTO> {
    const url = this.baseUrl + '/leave-type/'+id+'';
    return this.http.patch<any>(url, updateLeaveTypeDTO);
  }

}
