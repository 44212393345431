import { Route } from '@angular/router';
import { adminGuard } from '@core/guards/admin.guards';
import { UsersManagementComponent } from './users-management.component';
import { CreateUserComponent } from '@management/users-management/user/create-user/create-user.component';
import { AddUserToOfficeComponent } from './user/add-user-to-office/add-user-to-office.component';
import { EditUserComponent } from './user/edit-user/edit-user.component';

export const USERS_MANAGEMENT_ROUTES: Route[] = [
  { path: '', component: UsersManagementComponent, canActivate: [adminGuard] },
  { path: 'user/create', component: CreateUserComponent, canActivate: [adminGuard] },
  { path: 'user/edit/:id', component: EditUserComponent, canActivate: [adminGuard] },
  { path: 'user/:id/add-to-office', component: AddUserToOfficeComponent, canActivate: [adminGuard] },

];
