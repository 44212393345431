import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { DashboardManagerDTO } from '@model/dashboardManagerDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardManagerApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getDashboardData(): Observable<DashboardManagerDTO> {
    const url = this.baseUrl + '/dashboard/manager';
    return this.http.get<any>(url );
  }

}
