import { Component, Input  } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-management-header',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './management-header.component.html',
  styleUrl: './management-header.component.css'
})
export class ManagementHeaderComponent {
  faCirclePlus = faCirclePlus;
  @Input() headTitle: string = 'Management';
  @Input() actionButtonText: string = 'Add';
  @Input() modalId: string = '';

}
