<div class="d-flex flex-row mb-5 management-header" style="border-bottom: 1px solid #d2cdcd;
padding-bottom: 1rem;">
  <h2 routerLink="/management/offices">
    <span style="cursor: pointer;color: #0d6efd;">Office Management </span>
  </h2>
  
</div>


<div class="form-container">
    <form (ngSubmit)="onSubmit()">
        <div id="office-data">
            <div class="row">
            <div class="form-group mb-3 col-md-6">
                <label for="name">Office Name</label>
                <input type="text" id="name" class="form-control" [(ngModel)]="formData.name" name="name" />
            </div>
            <div class="form-group mb-3 col-md-6">
                <label for="description">Office Location</label>
                <select *ngIf="office" class="form-select form-select-sm" [id]="office.id+'country'" [(ngModel)]="office.country" name="country">
                    <option [value]="country.countryCode" *ngFor="let country of countries">
                        {{country.countryCode}} - {{country.name}}
                    </option>
                </select>
            </div>
        </div>
            <div class="form-group">
                <label for="description">Office Description</label>
                <textarea id="description" class="form-control" [(ngModel)]="formData.description" name="description"  rows="4" cols="50"></textarea>
            </div>
        </div>
        <div class="form-footer d-flex gap-3">
            <button type="submit" class="btn btn-primary btn-sm ms-auto py-2 px-4">Submit</button>
            <button class="btn btn-secondary btn-sm py-2 px-4" routerLink="/management/offices">Cancel</button>
          </div>
    </form>
</div>