<app-modal [modalId]="'leaveType'" title="Add new Office" [isForm]="true">
  <div class="container-fluid mt-4">
    <form [formGroup]="leaveTypeForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- Name Field -->
        <div class="mb-3 col-md-6">
          <label for="name" class="form-label">Name</label>
          <input type="text" id="name" name="name" class="form-control form-control-sm" formControlName="name" required>
        </div>

        <!-- days Field -->
        <div class="mb-3 col-md-6">
          <label for="days" class="form-label">Days</label>
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="inputGroup-sizing-sm">1-365</span>
            <input type="number" id="days" name="days" class="form-control" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm" value="1" formControlName="days">
          </div>
        </div>

      </div>

      <div class="row">
        <!-- Half Day -->
        <div class="mb-3 col-md-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="halfDay" formControlName="halfDay">
            <label class="form-check-label" for="halfDay">
              Half Day
            </label>
          </div>
        </div>

        <!-- Roles Field (assuming roles are entered as a comma-separated string) -->
        <div class="mb-3 col-md-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="strictDays" formControlName="strictDays">
            <label class="form-check-label" for="strictDays">
              Strict Days
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Hald Day -->
        <div class="mb-3 col-md-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="commentRequired"
              formControlName="commentRequired">
            <label class="form-check-label" for="commentRequired">
              Comment Required
            </label>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="autoApprove" formControlName="autoApprove">
            <label class="form-check-label" for="autoApprove">
              Auto Approve
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Hald Day -->
        <div class="mb-3 col-md-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="canBeReported" formControlName="canBeReported">
            <label class="form-check-label" for="canBeReported">
              Can be reported
            </label>
          </div>
        </div>

        <!-- Color Field -->
        <div class="mb-3 col-md-6">
          <label for="color" class="form-label">Color</label>
          <input type="color" id="color" name="color" value="#7ec8e3" class="form-control form-control-sm" formControlName="color"
            required>
        </div>
      </div>

      <!--Submit Button -->
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" data-bs-dismiss="modal">Submit</button>
      </div>
    </form>
  </div>
</app-modal>



<app-management-header title="LeaveType Management" actionButtonText="Add new Leave" modalId="leaveType">
</app-management-header>

<div class="container-fluid mt-4 user-management clear-user-agent-styles">

  <table class="table table-bordered">
    <thead>
      <tr>
        <th class="role-bar"></th>
        <th>Name</th>
        <th class="conector"></th>
        <th>Days</th>
        <th>Half Day</th>

        <th>Strict Days</th>
        <th>Color</th>
        <th>Comment Required</th>
        <th>AutoApprove</th>
        <th>Can be Reported</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let leaveType of leaveTypeList">
        <td class="role-bar"></td>
        <td><input type="text" class="form-control form-control-sm" [id]="leaveType.id+'name'"
            [(ngModel)]="leaveType.name"></td>
        <th class="conector">
          <div class="line"></div>
        </th>
        <td>
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="inputGroup-sizing-sm">1-365</span>
            <input type="number" id="days" name="days" class="form-control" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm" [value]="leaveType.days" [(ngModel)]="leaveType.days">
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [value]="leaveType.halfDay" [id]="leaveType.id+'halfDay'"
              [(ngModel)]="leaveType.halfDay">
            <label class="form-check-label" [for]="leaveType.id+'halfDay'">
              Half Day
            </label>
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [value]="leaveType.strictDays"
              [id]="leaveType.id+'strictDays'" [(ngModel)]="leaveType.strictDays">
            <label class="form-check-label" [for]="leaveType.id+'strictDays'">
              Strict Days
            </label>
          </div>
        </td>
        <td>
          <input type="color" [(ngModel)]="leaveType.color">
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [value]="leaveType.commentRequired"
              [id]="leaveType.id+'commentRequired'" [(ngModel)]="leaveType.commentRequired">
            <label class="form-check-label" [for]="leaveType.id+'commentRequired'">
              Comment Required
            </label>
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [value]="leaveType.autoApprove"
              [id]="leaveType.id+'autoApprove'" [(ngModel)]="leaveType.autoApprove">
            <label class="form-check-label" [for]="leaveType.id+'autoApprove'">
              Auto Approve
            </label>
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [value]="leaveType.canBeReported"
              [id]="leaveType.id+'canBeReported'" [(ngModel)]="leaveType.canBeReported">
            <label class="form-check-label" [for]="leaveType.id+'canBeReported'">
              Can be Reported
            </label>
          </div>
        </td>
        <th class="conector">
          <div class="line"></div>
        </th>
        <td class="text-center" style="width: 150px;">
          <button class="btn hover-primary px-2" type="button" (click)="update(
              leaveType.id,
              leaveType.name,
              leaveType.days,
              leaveType.halfDay,
              leaveType.strictDays,
              leaveType.commentRequired,
              leaveType.autoApprove,
              leaveType.canBeReported,
              leaveType.color)" data-bs-toggle="modal" data-bs-target="#editLeaveTipeData">
            <mat-icon class="material-icons-outlined">mode_edit_outline</mat-icon>
          </button>
          <button class="btn hover-primary px-2" type="button btn-danger" (click)="delete(leaveType.id)">
            <mat-icon class="material-icons-outlined">delete_forever</mat-icon>
          </button>

        </td>
      </tr>
    </tbody>
  </table>
</div>