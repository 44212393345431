import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { DownloadYearReportsDTO } from '../model/downloadYearReportsDTO';
import { DownloadYearReportsUserDTO } from '../model/downloadYearReportsUserDTO';
import { DownloadAttendanceDTO } from '../model/downloadAttendanceDTO';
import { DownloadAttendanceUserDTO } from '../model/downloadAttendanceUserDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getDownloadYearReports(queryParams: DownloadYearReportsDTO): Observable<HttpResponse<Blob>> {
    const url = this.baseUrl + '/download/year-reports';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get(url, { params , responseType:'blob', observe: 'response'});
  }

  getDownloadYearReportsUser(queryParams: DownloadYearReportsUserDTO): Observable<HttpResponse<Blob>> {
    const url = this.baseUrl + '/download/year-reports/user';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get(url, { params , responseType:'blob', observe: 'response'});
  }

  getDownloadAttendanceReports(queryParams: DownloadAttendanceDTO): Observable<HttpResponse<Blob>> {
    const url = this.baseUrl + '/download/attendance-reports';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get(url, { params , responseType:'blob', observe: 'response'});
  }

  getDownloadAttendanceReportsUser(queryParams: DownloadAttendanceUserDTO): Observable<HttpResponse<Blob>> {
    const url = this.baseUrl + '/download/attendance-reports/user';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get(url, { params , responseType:'blob', observe: 'response'});
  }

}
