import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router,  RouterModule } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserDTO } from '@model/userDTO';
import { UsersApiService } from '@api/users.service';
import { UpdateUserComponent } from '../user/update-user/update-user.component';
import { ActionMenuComponent } from '@components/menu/action-menu/action-menu.component';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';

@Component({
  selector: 'app-get-users',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ActionMenuComponent,
    ModalComponent,
    MatIconModule,
    UpdateUserComponent,
    AlertComponent,
    RouterModule
  ],
  templateUrl: './get-users.component.html',
  styleUrl: './get-users.component.css',
})
export class GetUsersComponent {
  @Output() changeView = new EventEmitter<string>();
  @Output() selectedUser = new EventEmitter<UserDTO>();
  @Input() officeId: number = 0;
  @Input() isOffice = false;
  @Input() isUsers = true;
  users: Array<UserDTO | any> = [];
  currentUser: UserDTO | any;
  selectedOfficeId: number = 0;
  selectedUserID: number = 0;
  searchTerm: string = '';
  filteredUsers: Array<UserDTO | any> = [];
  showCreateUserMessage: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userApi: UsersApiService,
    private titleService: Title,
    private officeApi: OfficeApiService,
    private alertService: AlertService,
    private router: Router
  ) {
    // this.getUsers()
  }

  ngOnInit(): void {
    console.log('Component is initialized, making an API call...');
    this.titleService.setTitle('User Management');
    this.getUsers();
  }

  editUser(user: UserDTO) {
    console.log('cancel');
    this.router.navigate(['management/users/user/edit', user.id]);
  }

  getUsers() {
    this.userApi
      .getUsersList({
        page: 0,
        pageSize: 100,
      })
      .subscribe((value) => {
        console.log(value);
        this.users = value.content;
        this.filteredUsers = this.users;
      });
  }
  // filterUsers() {
  //   this.filteredUsers = this.users.filter((user: UserDTO) => {
  //     const fullName = user.firstName + ' ' + user.lastName;
  //     return fullName.toLowerCase().includes(this.searchTerm.toLowerCase());
  //   });
  // }
  filterUsers() {
    this.showCreateUserMessage = true;
    this.userApi
      .getUsersList({
        page: 0,
        pageSize: 100,
        searchText: this.searchTerm,
      })
      .subscribe((value) => {
        console.log(value);
        this.filteredUsers = value.content;
      });
  }
  update(
    id: number,
    firstName: string,
    lastName: string,
    password: string | undefined,
    email: string,
    confirmedEmail: boolean,
    roles: string | any
  ) {
    var pass = password;

    if (!isPasswordValid(password)) {
      pass = undefined;
    }

    this.userApi
      .patchUsers(id, {
        email,
        firstName,
        lastName,
        emailConfirmed: confirmedEmail,
        password: pass,
        roles: roles,
      })
      .subscribe(() => {
        this.getUsers();
      });
  }
  delete(id: number, name: string) {
    if (
      confirm(
        `Are You sure you want to delete the user ${name}? All records will be lost `
      )
    )
      this.userApi.deleteUsers(id).subscribe(() => {
        this.getUsers();
      });
  }

  addUserToOffice(officeId: number, selectedUserId: number) {
    const ids: number[] = [selectedUserId];

    this.officeApi.postOfficeUser(officeId, { userIds: ids }).subscribe(() => {
      console.log('User Added to Office');
      this.alertService.sendAlertSuccess('The user was added to the office');
    });
  }
  createNewUser() {
    this.router.navigate(['/management/users/user/create']);
  }
}

function isPasswordValid(password: any) {
  if (password === undefined || password === null) {
    return false;
  }
  const trimmedPassword = password.trim();
  return trimmedPassword !== '';
}
