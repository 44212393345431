<div class="container-fluid mt-4 user-management clear-user-agent-styles">
  <input type="text" class="form-control mb-3" [(ngModel)]="searchTerm" (input)="filterUsers()" placeholder="Search by name">
  <table class="table table-bordered">
    <thead class="user-list">
      <tr>
        <!-- <th>ID</th> -->
        <th class="role-bar"></th>
        <th class="user-name">Name</th>
        <th class="conector"></th>
        <!-- <th>Password</th> -->
        <th class="user-email">Email</th>
        <th>Confirmed Email</th>
        <th class="office">Office</th>
        <th><span class="role">Roles</span></th>
        <th class="conector"></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of filteredUsers">
        <!-- <td><input type="text" class="form-control form-control-sm" [(ngModel)]="user.id" disabled></td> -->
        <td class="role-bar" [ngClass]="user.roles"></td>
        <td class="user-name">{{user.firstName}}  {{user.lastName}}</td>
        <th class="conector">
          <div class="line"></div>
        </th>
        <td class="user-email">{{user.email}}</td>
        <td>{{user.confirmedEmail}}</td>
        <td *ngIf="user?.assignedOffice">
          {{ user.assignedOffice.name }}
        </td>
        <td *ngIf="!user?.assignedOffice">
          <a [routerLink]="['/management/users/user/', user?.id, 'add-to-office']" [queryParams]="{ key: 'member', name: user?.firstName + ' ' + user?.lastName }">Add to Office</a>
        </td>
        <td class="user-role"><span [ngClass]="user.roles">{{user.roles}} </span></td>
       <th class="conector">
          <div class="line"></div>
        </th>

        <td class="text-center" >
          <button  class="btn hover-primary px-2" type="button" (click)="editUser(user)">
              <mat-icon class="material-icons-outlined">mode_edit_outline</mat-icon>
          </button>
          <button  class="btn hover-primary px-2" type="button"(click)="delete(user.id, user.name)">
              <mat-icon class="material-icons-outlined">delete_forever</mat-icon>
          </button>
      
      </td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="filteredUsers.length === 0">
    <div class="container" *ngIf="showCreateUserMessage">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="text-center mt-5">
            <h2>No users found</h2>
            <p>There are no users in the database that match your search. You can create a new user.</p>
            <button class="btn btn-primary" (click)="createNewUser()">Create New User</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
