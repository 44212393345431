import { CommonModule } from '@angular/common';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { Component, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {MatIconModule} from '@angular/material/icon';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
} from '@angular/forms';
import { LabelComponent } from '@components/form/label/label.component';
import { UserDTO } from '@model/userDTO';
import { UsersApiService } from '@api/users.service';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';

@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule, RouterModule,MatIconModule, AlertComponent, LabelComponent],
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
})
export class CreateUserComponent implements OnInit {
  users: Array<UserDTO | any> = [];
  countryList: Array<String> = ['RO', 'DE'];
  officeId: string | null = null;
  hidePassword = true;
  hideConfirmPassword = true;
  errorMessages: { [key: string]: string } = {};
  userForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: [''],
    roles: ['', Validators.required],
    emailConfirmed: [false],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    personalNumber: ['', Validators.required],
    socialSecurityNumber: ['', Validators.required],
    gender: ['', Validators.required],
    birthday: ['', Validators.required],
    privateEmail: ['', Validators.email],
    maritalStatus: ['', Validators.required],
    parentalStatus: ['', Validators.required],
    singleParent: [false],
    highestProfessionalEducation: ['', Validators.required],
    schoolLeavingCertificate: ['', Validators.required],
    occupation: ['', Validators.required],
    startDate: ['', Validators.required],
    weeklyWorkingHours: [0, Validators.required],
    vacationEntitlement: [0, Validators.required],
    contractType: ['', Validators.required],
    severeDisability: ['', Validators.required],
    employmentStatus: ['', Validators.required],
    typeOfWorkplace: ['', Validators.required],
    iban: ['', Validators.pattern(/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/)],
    address: this.fb.array([this.createAddressGroup()]),
    phones: this.fb.array([this.createPhoneGroup()]),
    children: this.fb.array([this.createChildrenGroup()]),
  });

  constructor(
    private fb: FormBuilder,
    private userApi: UsersApiService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private alertService: AlertService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    console.log('Component is initialized, making an API call...');
    this.titleService.setTitle('Create User');
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.officeId = params['id'];
      }
    });
    this.userForm.get('parentalStatus')?.valueChanges.subscribe((value) => {
      this.toggleChildrenValidators(value === 'yes');
    });
  }

  createAddressGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      type: ['', Validators.required],
      country: [
        '',
        Validators.compose([Validators.required, Validators.pattern(/^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|CV|KH|CM|CA|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)$/)]),
      ],
      city: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
  }
  get addressArray(): FormArray {
    return this.userForm.get('address') as FormArray;
  }
  createPhoneGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      contact: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      type: [
        '',
        Validators.compose([Validators.required, Validators.pattern(/^(MOBILE|FIXED|EMERGENCY1|EMERGENCY2|EMERGENCY3)$/)]),
      ],
    });
  }

  createChildrenGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      name: [''],
      birthday: [''],
    });
  }
  toggleChildrenValidators(isParent: boolean): void {
    const childrenArray = this.userForm.get('children') as FormArray;
    if (isParent) {
      childrenArray.controls.forEach(group => {
        group.get('name')?.setValidators([Validators.required]);
        group.get('birthday')?.setValidators([Validators.required]);
        group.get('name')?.updateValueAndValidity();
        group.get('birthday')?.updateValueAndValidity();
      });
    } else {
      childrenArray.controls.forEach(group => {
        group.get('name')?.clearValidators();
        group.get('birthday')?.clearValidators();
        group.get('name')?.updateValueAndValidity();
        group.get('birthday')?.updateValueAndValidity();
      });
    }
  }
  get addressForms() {
    return this.userForm.get('address') as FormArray;
  }

  get phoneForms() {
    return this.userForm.get('phones') as FormArray;
  }

  get childrenForms() {
    return this.userForm.get('children') as FormArray;
  }

  addAddress() {
    this.addressForms.push(this.createAddressGroup());
  }

  removeAddress(index: number) {
    this.addressForms.removeAt(index);
  }

  addPhone() {
    this.phoneForms.push(this.createPhoneGroup());
  }

  removePhone(index: number) {
    this.phoneForms.removeAt(index);
  }

  addChild() {
    this.childrenForms.push(this.createChildrenGroup());
  }

  removeChild(index: number) {
    this.childrenForms.removeAt(index);
  }
  onCancel() {
    const currentUrl = this.route.url;
    if (this.officeId) {
      this.router.navigate(['./management/offices/office', this.officeId, 'users']);
    } else {
      this.router.navigate(['./management/users']);
    }
  }

  // onSubmit() {
  //   console.log('Form submitted', this.userForm);
  //   if (this.userForm.valid) {
  //     console.log('Form is valid', this.userForm.value);
  //     this.userApi.postUsers(this.userForm.value).subscribe(
  //       (response) => {
  //         console.log('response', response);
  //         this.router.navigate(['/management/users/user/', response.id, 'add-to-office'], {
  //           queryParams:{
  //             key: 'newMember',
  //             name: `${response.firstName} ${response.lastName}`
  //           }
  //         });
  //       },
  //       (error) => {
  //         console.error('Error', error);
  //         this.alertService.sendAlertError(error.error.errorMessage
  //         )
  //       }
  //     );
  //   } else {
  //     console.log('Form is invalid', this.userForm.errors);
  //     this.userForm.markAllAsTouched(); // Mark all fields as touched to display validation errors
  //     this.scrollToFirstError();
  //     this.alertService.sendAlertError('Some fields in form are not valid')
      
  //   }
  // }
  onSubmit() {
    console.log('Form submitted', this.userForm);
    if (this.userForm.valid) {
        console.log('Form is valid', this.userForm.value);
        this.userApi.postUsers(this.userForm.value).subscribe(
            (response) => {
                console.log('response', response);
                this.router.navigate(['/management/users/user/', response.id, 'add-to-office'], {
                    queryParams: {
                        key: 'newMember',
                        name: `${response.firstName} ${response.lastName}`
                    }
                });
            },
            (error) => {
                console.error('Error', error);
                if (error.error.errorCode === 400 && error.error.errorMessage === "User already added!") {
                    this.errorMessages['email'] = "Email is already used by another member.";
                    const emailControl = this.userForm.get('email');
                    console.error('Error', this.errorMessages['email']);
                    if (emailControl) {
                      this.alertService.sendAlertError('Email is already used.');
                        emailControl.markAsTouched()
                        emailControl.setErrors({ 'emailInUse': true });
                    }
                    setTimeout(() => this.scrollToFirstError(), 0);
                    this.scrollToFirstError();
                } else {
                    this.alertService.sendAlertError('Some fields in form are not valid');
                }
            }
        );
    } else {
        console.log('Form is invalid', this.userForm.errors);
        this.userForm.markAllAsTouched(); // Mark all fields as touched to display validation errors
        setTimeout(() => this.scrollToFirstError(), 0);
        this.scrollToFirstError();
    }
}


  private scrollToFirstError() {
    const firstErrorElement = this.el.nativeElement.querySelector('.text-danger');
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
