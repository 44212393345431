import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { LoginService } from '@login/login.service';

export const authGuard: CanActivateFn = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> => {
  const loginService = inject(LoginService);
  const router = inject(Router);

  if (loginService.isLogin() && loginService.isValidToken(loginService.getAccessToken())) {
    return true;
  } else {
    const refreshed = await loginService.refreshTokenIfNeeded();
    if (refreshed && loginService.isLogin() && loginService.isValidToken(loginService.getAccessToken())) {
      return true;
    } else {
      return router.createUrlTree(['/home']);
    }
  }
};
