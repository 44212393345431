import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
} from '@angular/forms';
import { LabelComponent } from '@components/form/label/label.component';
import { UserDTO } from '@model/userDTO';
import { UsersApiService } from '@api/users.service';


@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule,LabelComponent],
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.css',
})
export class CreateUserComponent {
  @Output() changeView = new EventEmitter<string>();
  @Output() userId = new EventEmitter<number>();
  users: Array<UserDTO | any> = [];

  userForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    roles: ['', Validators.required],
    emailConfirmed: [false],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    personalNumber: ['string'],
    socialSecurityNumber: ['string'],
    gender: ['string'],
    birthday: ['2024-04-16'],
    privateEmail: ['string'],
    maritalStatus: ['string'],
    parentalStatus: ['string'],
    singleParent: [true],
    highestProfessionalEducation: ['string'],
    schoolLeavingCertificate: ['string'],
    occupation: ['string'],
    startDate: ['2024-04-16'],
    weeklyWorkingHours: [0],
    vacationEntitlement: [0],
    contractType: ['FULL_TIME'],
    severeDisability: ['string'],
    employmentStatus: ['string'],
    typeOfWorkplace: ['string'],
    iban: ['string'],
    address: this.fb.array([this.createAddressGroup()]),
    phones: this.fb.array([this.createPhoneGroup()]),
    children: this.fb.array([this.createChildrenGroup()]),
  });

 
  createAddressGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      type: ['string'],
      country: ['AF'],
      city: ['string'],
      street: ['string'],
      postalCode: ['string'],
    });
  }
  createPhoneGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      contact: ['string'],
      phoneNumber: ['string'],
      type: ['MOBILE'],
    });
  }
  createChildrenGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      name: ['string'],
      birthday: ['2024-04-16'],
    });
  }
  constructor(private fb: FormBuilder, private userApi: UsersApiService, private titleService: Title, private route: Router) {}
  ngOnInit(): void {
    console.log('Component is initialized, making an API call...');
    this.titleService.setTitle("Create User");
    
  }
 onCancel(){
  this.route.navigate(['./'])
 }
  onSubmit() {
    if (this.userForm.valid) {
     console.log('this.', this.userForm.value);
      this.userApi.postUsers(this.userForm.value).subscribe((response) => {
        console.log('Server response:', response);
        this.changeView.emit('user-created');
        this.userId.emit(response.id);
      });
    }
  }
 
}
