import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Component } from '@angular/core';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {MatIconModule} from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';




@Component({
  selector: 'app-office-management',
  standalone: true,
  imports: [CommonModule, PopoverModule, MatIconModule, RouterModule, AlertComponent],
  templateUrl: './office-management.component.html',
  styleUrls: ['./office-management.component.css']
})
export class OfficeManagementComponent {
  
  offices: Array<OfficeDTO> = [];
  
  popoverTitle: string = "";
  popoverContent: string = "";

  constructor(private officeApi:OfficeApiService, private headTitle: Title,private alertService: AlertService, private router: Router ) { }
 
  getOffices() {
    this.officeApi.getOffice({ pageSize: 100 }).subscribe(value => {
      this.offices = value.content;
      console.log(" this.getOffices()",this.offices)
    })
  }
  ngOnInit(): void {
    this.headTitle.setTitle("Offices Management");
    this.getOffices()
   
  }
  navigateToEditOfficeMembers(officeId: number){
this.router.navigate(["./management/offices/office/", officeId, "users"])
  }
  navigateToEditOffice(officeId: number){
    this.router.navigate(["./management/offices/office/", officeId, "edit"])
      }
  delete(id: number, name:string) {
    if(confirm(`Are You sure you want to delete the user ${name}? All records will be lost `))
    this.officeApi.deleteOffice(id).subscribe(() => {
      this.alertService.sendAlertSuccess('Office was deleted succesfuly');
      this.getOffices()
    })
  }
}


