<div class="container-fluid mt-4 p-4">
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div>
      <div class="row">
        <!-- Name Field -->
        <div class="mb-3 col-md-6">
          <app-label [forAttribute]="'firstName'" [labelText]="'First Name 2'" [isRequired]="true"></app-label>
          <input type="text" id="firstName" name="firstName" class="form-control form-control-sm"
            formControlName="firstName" required>
        </div>
        <div class="mb-3 col-md-6">
          <label for="lastName" class="form-label">Last Name</label>
          <input type="text" id="lastName" name="lastName" class="form-control form-control-sm"
            formControlName="lastName" required>
        </div>
        <!-- Email Field -->
        <div class="mb-3 col-md-6">
          <label for="email" class="form-label">Email</label>
          <input type="email" id="email" name="email" class="form-control form-control-sm" formControlName="email"
            required>
        </div>
      </div>

      <div class="row">
        <!-- Password Field -->
        <div class="mb-3 col-md-6">
          <label for="password" class="form-label">Password</label>
          <input type="password" id="password" name="password" class="form-control form-control-sm"
            formControlName="password" required>
        </div>

        <!-- Roles Field (assuming roles are entered as a comma-separated string) -->
        <div class="mb-3 col-md-6">
          <label for="roles" class="form-label">Roles</label>
          <select id="roles" name="roles" class="form-control form-control-sm" formControlName="roles">
            <option value="MANAGER">MANAGER</option>
            <option value="EMPLOYEE">EMPLOYEE</option>
          </select>
        </div>
      </div>

      <!-- Email Confirmation Checkbox -->
      <div class="mb-3 form-check">
        <input type="checkbox" id="emailConfirmed" name="emailConfirmed" class="form-check-input"
          formControlName="emailConfirmed">
        <label for="emailConfirmed" class="form-check-label">Email Confirmed</label>
      </div>

      <!-- New Fields -->
      <!-- Add additional fields here -->

    </div>
    <!-- Submit Button -->
    <div class="d-flex gap-3">
      <button type="submit" class="btn btn-primary btn-sm ms-auto py-2 px-4">Submit</button>
      <button type="submit" class="btn btn-secondary btn-sm py-2 px-4" (cancel)="onCancel()" >Cancel</button>
    </div>
  </form>
</div>