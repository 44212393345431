import { EventEmitter, Injectable } from '@angular/core';
import { UserDTO } from '../model/userDTO';
import { ResponseTokenDTO } from '../model/responseTokenDTO';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private baseUrl = environment.apiURL;
  private accessToken: string | undefined;
  private refreshToken: string | undefined;
  private _isLogin: boolean = false;
  private user: UserDTO | undefined;
  private _loginEvent: EventEmitter<{
    user: UserDTO | undefined;
    isLogin: boolean;
  }> = new EventEmitter<{ user: UserDTO | undefined; isLogin: boolean }>();

  constructor() {}

 
  checkCanLogin(cb: (arg0: string) => void) {
    if (this.refreshToken === undefined) {
      this.refreshToken = localStorage.getItem('refresh_token') as string;
    }
    if (this.isValidToken(this.refreshToken)) {
      cb(this.refreshToken);
    }
  }

  getAccessToken(): string | undefined {
    return this.accessToken;
  }

  getRefreshToken(): string | undefined {
    return this.refreshToken;
  }

  getUser(): UserDTO | undefined {
    return this.user;
  }

  loginEvent(): EventEmitter<{ user: UserDTO | undefined; isLogin: boolean }> {
    return this._loginEvent;
  }

  public setData(response: ResponseTokenDTO) {
    this.accessToken = response.accessToken;
    this.refreshToken = response.refreshToken;
    this.user = response.user;
    this._isLogin = true;
    localStorage.setItem('refresh_token', response.refreshToken as string);
    this._loginEvent.emit({ user: response.user, isLogin: true });
  }

  public clearTokens() {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.user = undefined;
    this._isLogin = false;
    localStorage.clear();
    this._loginEvent.emit({ user: undefined, isLogin: false });
  }

  public isLogin(): boolean {
    return this._isLogin;
  }

  public isUserAdmin(): boolean {
    console.log('this.user?.roles', this.user?.roles);
    return this.user?.roles.includes('MANAGER') ?? false;
  }

  // public isValidToken(token: string | undefined): boolean {
  //   if (typeof token !== 'string' || token === undefined) {
  //     return false;
  //   }
  //   let isJwtExpired = true;
  //   const decoded: any = jwtDecode(token);

  //   const currentTime = new Date().getTime() / 1000;
  //   if (currentTime > decoded.exp) {
  //     isJwtExpired = false;
  //   }
  //   return isJwtExpired;
  // }
  public isValidToken(token: string | undefined): boolean {
    if (!token || typeof token !== 'string') {
      return false;
    }
  
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return currentTime <= decoded.exp;
    } catch (error) {
      console.error('Error decoding token:', error);
      return false;
    }
  }
  public async refreshTokenIfNeeded(): Promise<boolean> {
    if (!this.refreshToken) {
      this.refreshToken = localStorage.getItem('refresh_token') as string;
    }
    
    if (this.isValidToken(this.refreshToken)) {
      try {
        const newAccessToken = await this.getNewAccessToken();
        if (newAccessToken) {
          this.accessToken = newAccessToken;
          localStorage.setItem('access_token', this.accessToken); 
          return true; 
        } else {
          throw new Error('A new access token could not be obtained');
        }
      } catch (error) {
        console.error('Error while updating access token:', error);
        this.clearTokens(); 
        return false;
      }
    }
    
    this.clearTokens();
    return false;
  }
  
  private async getNewAccessToken(): Promise<string | undefined> {
    try {
      if (!this.refreshToken) {
        throw new Error('No refresh token available');
      }
  
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'refresh-token': this.refreshToken,
      };
      const url = this.baseUrl + '/auth/token'
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({}),
      });
  
      if (!response.ok) {
        throw new Error('Request failed');
      }
  
      const data = await response.json();
      if (data.accessToken) {
        return data.accessToken;
      } else {
        throw new Error('Access token not provided in response');
      }
    } catch (error) {
      console.error('Error fetching new access token:', error);
      return undefined;
    }
  }
  
}
