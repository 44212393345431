import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { PageList } from '../model/pageList';
import { AnnualLeaveReportQueryDTO } from '../model/annualLeaveReportQueryDTO';
import { environment } from 'environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AnnualReportsApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getAnnualReports(queryParams: AnnualLeaveReportQueryDTO): Observable<PageList> {
    const url = this.baseUrl + '/annual-reports';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

  getAnnualReportsUser(queryParams: AnnualLeaveReportQueryDTO): Observable<PageList> {
    const url = this.baseUrl + '/annual-reports/user';
    const params = new HttpParams({ fromObject: queryParams as any });
    return this.http.get<any>(url, { params });
  }

}
