<!-- <div class="hero">Hero</div>
<div class="features">Feature</div>
<div class="benefits">Benefits</div>
<p>This should be home page!</p> -->
<div class="container">
  <div class="row mt-5">
    <div class="col-md-6 offset-md-3 text-center">
      <!-- Logo -->
      <img src="/assets/logo.png" alt="Logo" class="logo">
      <!-- Welcome Message -->
      <h1 class="welcome-message">Welcome to Time Tracking App!</h1>
      <p>This application helps you manage time tracking and leaves.</p>
      <!-- Angular Router Link Button -->
      <div><span class="text-primary m-4 py-4">Don't have an account yet?</span></div>
      <a routerLink="/register" class="btn btn-primary mt-3">Register</a>
    </div>
  </div>
</div>