import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { UpdateCompanyDTO } from '../model/updateCompanyDTO';
import { CompanyDTO } from '../model/companyDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getCompany(): Observable<CompanyDTO> {
    const url = this.baseUrl + '/company';
    return this.http.get<any>(url );
  }

  patchCompany( updateCompanyDTO: UpdateCompanyDTO): Observable<CompanyDTO> {
    const url = this.baseUrl + '/company';
    return this.http.patch<any>(url, updateCompanyDTO);
  }

}
