<div class="container-fluid mt-4 p-4">
  <form class="form-container" [formGroup]="userForm" (ngSubmit)="onSubmit()">

    <div class="row">
      <h3>User Name</h3>
      <!-- First Name Field -->
      <div class="mb-1 col-md-6">
        <label for="firstName" class="form-label">First Name</label>
        <input type="text" id="firstName" name="firstName" class="form-control form-control-sm"
          formControlName="firstName" required>
        <div *ngIf="userForm.get('firstName')?.invalid && userForm.get('firstName')?.touched" class="text-danger">
          First name is required.
        </div>
      </div>
      <!-- Last Name Field -->
      <div class="mb-1 col-md-6">
        <label for="lastName" class="form-label">Last Name</label>
        <input type="text" id="lastName" name="lastName" class="form-control form-control-sm" formControlName="lastName"
          required>
        <div *ngIf="userForm.get('lastName')?.invalid && userForm.get('lastName')?.touched" class="text-danger">
          Last name is required.
        </div>
      </div>
    </div>

    <div class="row">
      <h3>Login Data</h3>
      <!-- Email Field -->
      <div class="mb-1 col-md-6">
        <label for="email" class="form-label">Email</label>
        <input type="email" id="email" name="email" class="form-control form-control-sm" formControlName="email"
          required>
        <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched" class="text-danger">
          <div *ngIf="userForm.get('email')?.errors?.['required']">Email is required.</div>
          <div *ngIf="userForm.get('email')?.errors?.['email']">Invalid email format.</div>
        </div>
      </div>
      <div class="mb-1 form-check col-md-6">
        <label for="confirmedEmail" class="form-check-label">Email Confirmed</label>
        <input type="checkbox" id="confirmedEmail" class="form-check-input" formControlName="confirmedEmail">
      </div>
      <!-- Password Field -->
      <div class="mb-1 col-md-6">
        <label for="password" class="form-label">Password</label>
        <div class="password-container">
          <input [type]="hidePassword ? 'password' : 'text'" id="password" name="password"
            class="form-control form-control-sm" formControlName="password">
          <div class="password-icon">
            <span class="input-group-text" (click)="hidePassword = !hidePassword">
              <mat-icon class="material-icons-outlined">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </span>
          </div>
        </div>
      </div>

      <div class="mb-1 col-md-6">
        <label for="confirmPassword" class="form-label">Confirm Password</label>
        <div class="password-container">
          <input [type]="hideConfirmPassword ? 'password' : 'text'" id="confirmPassword" name="confirmPassword"
            class="form-control form-control-sm" formControlName="confirmPassword">
          <div class="password-icon">
            <span class="input-group-text " (click)="hideConfirmPassword = !hideConfirmPassword">
              <mat-icon
                class="material-icons-outlined">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </span>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <h3>Contract Details</h3>
      <!-- Roles Field -->

     
<div class="mb-1 col-md-4">
  <label for="roles" class="form-label">Roles</label>
  <select id="roles" name="roles" class="form-control form-control-sm form-select" formControlName="roles" required>
    <option value="">Select Role</option>
    <option value="MANAGER">MANAGER</option>
    <option value="EMPLOYEE">EMPLOYEE</option>
  </select>
  <div *ngIf="userForm.get('roles')?.invalid && userForm.get('roles')?.touched" class="text-danger">
    Role is required.
  </div>
</div>
<div class="mb-1 col-md-4">
  <label for="contractType" class="form-label">Contract Type</label>
  <select id="contractType" name="contractType" class="form-control form-control-sm form-select"
    formControlName="contractType" required>
    <option value="">Select Contract Type</option>
    <option value="FULL_TIME">FULL TIME</option>
    <option value="PART_TIME">PART TIME</option>
    <option value="FIXED_TERM">FIXED TERM</option>
    <option value="PERMANENT">PERMANENT</option>
    <option value="REMOTE_WORK">REMOTE WORK</option>
    <option value="HOURLY">HOURLY</option>
    <option value="FREELANCE">FREELANCE</option>
    <option value="CONSULTING">CONSULTING</option>
    <option value="VOLUNTEER">VOLUNTEER</option>
    <option value="TEMPORARY">TEMPORARY</option>
    <option value="ZERO_HOUR">ZERO HOUR</option>
  </select>
  <div *ngIf="userForm.get('contractType')?.invalid && userForm.get('contractType')?.touched" class="text-danger">
    Contract type is required.
  </div>
</div>
      <!-- Occupation Field -->
      <div class="mb-1 col-md-4">
        <label for="occupation" class="form-label">Occupation</label>
        <input type="text" id="occupation" class="form-control" formControlName="occupation">
      </div>

      <!-- Start Date Field -->
      <div class="mb-1 col-md-4">
        <label for="startDate" class="form-label">Start Date</label>
        <input type="date" id="startDate" name="startDate" class="form-control form-control-sm"
          formControlName="startDate" required>
        <div *ngIf="userForm.get('startDate')?.invalid && userForm.get('startDate')?.touched" class="text-danger">
          Start date is required.
        </div>
      </div>

      <!-- Weekly Working Hours Field -->
      <div class="mb-1 col-md-4">
        <label for="weeklyWorkingHours" class="form-label">Weekly Working Hours</label>
        <input type="number" id="weeklyWorkingHours" class="form-control" formControlName="weeklyWorkingHours" required>
      </div>
      <!-- Vacation Entitlement Field -->
      <div class="mb-1 col-md-4">
        <label for="vacationEntitlement" class="form-label">Vacation Entitlement</label>
        <input type="number" id="vacationEntitlement" class="form-control" formControlName="vacationEntitlement"
          required>
      </div>
    </div>

    <div class="row">
      <h3>Personal Data</h3>
      <!-- Personal Number Field -->
      <div class="mb-1 col-md-4">
        <label for="personalNumber" class="form-label">Personal Number</label>
        <input type="text" id="personalNumber" class="form-control" formControlName="personalNumber" required>
      </div>

      <!-- Social Security Number Field -->
      <div class="mb-1 col-md-4">
        <label for="socialSecurityNumber" class="form-label">Social Security Number</label>
        <input type="text" id="socialSecurityNumber" class="form-control" formControlName="socialSecurityNumber"
          required>
      </div>

      <!-- Severe Disability Field -->
      <div class="mb-1 col-md-4">
        <label for="severeDisability" class="form-label">Severe Disability</label>
        <input type="text" id="severeDisability" class="form-control" formControlName="severeDisability">
      </div>

      <!-- Employment Status Field -->
      <div class="mb-1 col-md-4">
        <label for="employmentStatus" class="form-label">Employment Status</label>
        <input type="text" id="employmentStatus" class="form-control" formControlName="employmentStatus">
      </div>

      <!-- Type Of Workplace Field -->
      <div class="mb-1 col-md-4">
        <label for="typeOfWorkplace" class="form-label">Type Of Workplace</label>
        <input type="text" id="typeOfWorkplace" class="form-control" formControlName="typeOfWorkplace">
      </div>

      <!-- IBAN Field -->
      <div class="mb-1 col-md-4">
        <label for="iban" class="form-label">IBAN</label>
        <input type="text" id="iban" name="iban" class="form-control form-control-sm" formControlName="iban">
<div *ngIf="userForm.get('iban')?.invalid && userForm.get('iban')?.touched" class="text-danger">
<div *ngIf="userForm.get('iban')?.errors?.['pattern']">Invalid IBAN format.</div>
</div>
</div>
<!-- Private Email Field -->
<div class="mb-1">
<label for="privateEmail" class="form-label">Private Email</label>
<input type="email" id="privateEmail" class="form-control" formControlName="privateEmail">

</div>
<!-- Birthday Field -->
<div class="mb-1 col-md-4">
<label for="birthday" class="form-label">Birthday</label>
<input type="date" id="birthday" name="birthday" class="form-control form-control-sm" formControlName="birthday"
       required>
<div *ngIf="userForm.get('birthday')?.invalid && userForm.get('birthday')?.touched" class="text-danger">
Birthday is required.
</div>
</div>
<!-- Gender Field -->
<div class="mb-1 col-md-4">
<label for="gender" class="form-label">Gender</label>
<input type="text" id="gender" name="gender" class="form-control form-control-sm" formControlName="gender"
       required>
<div *ngIf="userForm.get('gender')?.invalid && userForm.get('gender')?.touched" class="text-danger">
Gender is required.
</div>
</div>
<!-- Marital Status Field -->
  <div class="mb-1 col-md-4">
    <label for="maritalStatus" class="form-label">Marital Status</label>
    <input type="text" id="maritalStatus" class="form-control" formControlName="maritalStatus">
  </div>
  <div class="mb-1 col-md-4">
    <label for="parentalStatus" class="form-label">Parental Status</label>
    <select id="parentalStatus" class="form-select" formControlName="parentalStatus">
      <option value="">Select Status</option>
      <option value="no">No</option>
      <option value="yes">Yes</option>
    </select>
  </div>
  <div *ngIf="userForm.get('parentalStatus')?.value === 'yes'" class="mb-1 form-check col-md-12"
    style="margin-left: 1rem;">
    <input type="checkbox" id="singleParent" class="form-check-input" formControlName="singleParent">
    <label for="singleParent" class="form-check-label">Single Parent</label>
  </div>
  <div *ngIf="userForm.get('parentalStatus')?.value === 'yes'" formArrayName="children">
    <!-- Single Parent Field -->

    <div *ngFor="let child of childrenForms.controls; let i = index" [formGroupName]="i"
      class="row border p-3 mb-1 mt-4">
      <h5>Child {{ i + 1 }}</h5>
      <!-- Name Field -->
      <div class="mb-1 col-md-6">
        <label for="nameChild" class="form-label">Name</label>
        <input type="text" id="nameChild" name="name" class="form-control form-control-sm" formControlName="name">
        <div *ngIf="child.get('name')?.invalid && child.get('name')?.touched" class="text-danger">
          Name is required.
        </div>
      </div>
      <!-- Birthday Field -->
      <div class="mb-1 col-md-6">
        <label for="birthday" class="form-label ">Birthday</label>
        <input type="date" id="birthday" name="birthday" class="form-control form-control-sm"
          formControlName="birthday">
        <div *ngIf="child.get('birthday')?.invalid && child.get('birthday')?.touched" class="text-danger">
          Birthday is required.
        </div>
      </div>

      <div class="d-flex">
        <button type="button" class="btn btn-danger btn-sm ms-auto py-2 px-4"
          style="width: 200px;" (click)="removeChild(i)">Remove Child</button>
      </div>
    </div>
    <button type="button" class="btn btn-primary btn-sm ms-auto py-2 px-4 mt-4" (click)="addChild()">Add Child</button>
  </div>


</div>

<div class="row">
  <h3>Education</h3>
<!-- Highest Professional Education Field -->
      <div class="mb-1">
        <label for="highestProfessionalEducation" class="form-label">Highest Professional Education</label>
        <input type="text" id="highestProfessionalEducation" class="form-control"
          formControlName="highestProfessionalEducation">
      </div>

      <!-- School Leaving Certificate Field -->
      <div class="mb-1">
        <label for="schoolLeavingCertificate" class="form-label">School Leaving Certificate</label>
        <input type="text" id="schoolLeavingCertificate" class="form-control"
          formControlName="schoolLeavingCertificate">
      </div>

    </div>

    <div class="row">
      <h3>Address</h3>
      <!-- Addresses -->
      <div formArrayName="address">
        <div *ngFor="let address of addressForms.controls; let i = index" [formGroupName]="i" class="p-3 mb-1">

          <div class="row">
            <h5>Address {{ i + 1 }}</h5>
            <!-- Type Field -->
            <div class="mb-1 col-md-4">
              <label for="type" class="form-label">Type</label>
              <input type="text" id="typeAddress" name="type" class="form-control form-control-sm" formControlName="type"
                required>
              <div *ngIf="address.get('type')?.invalid && address.get('type')?.touched" class="text-danger">
                Type is required.
              </div>
            </div>
            <!-- Country Field -->
            <div class="mb-1 col-md-4">
              <label for="country" class="form-label">Country</label>
              <select id="country" name="country" class="form-control form-control-sm form-select"
                formControlName="country" required>
                <option value="">Select Country</option>
                <!-- Add all country options from the enum -->
                <option *ngFor="let country of countryList" [value]="country">{{ country }}</option>
              </select>
              <div *ngIf="address.get('country')?.invalid && address.get('country')?.touched" class="text-danger">
                <div *ngIf="address.get('country')?.errors?.['required']">Country is required.</div>
                <div *ngIf="address.get('country')?.errors?.['pattern']">Invalid country.</div>
              </div>
            </div>

            <!-- City Field -->
            <div class="mb-1 col-md-4">
              <label for="city" class="form-label">City</label>
              <input type="text" id="city" name="city" class="form-control form-control-sm" formControlName="city"
                required>
              <div *ngIf="address.get('city')?.invalid && address.get('city')?.touched" class="text-danger">
                City is required.
              </div>
            </div>

            <!-- Street Field -->
            <div class="mb-1 col-md-12">
              <label for="street" class="form-label">Street</label>
              <input type="text" id="street" name="street" class="form-control form-control-sm"
                formControlName="street" required>
              <div *ngIf="address.get('street')?.invalid && address.get('street')?.touched" class="text-danger">
                Street is required.
              </div>
            </div>
            <!-- Postal Code Field -->
            <div class="mb-1 col-md-4">
              <label for="postalCode" class="form-label">Postal Code</label>
              <input type="text" id="postalCode" name="postalCode" class="form-control form-control-sm"
                formControlName="postalCode" required>
              <div *ngIf="address.get('postalCode')?.invalid && address.get('postalCode')?.touched"
                class="text-danger">
                Postal code is required.
              </div>
            </div>
            <div class="d-flex">
              <button type="button" class="btn btn-danger btn-sm ms-auto py-2 px-4 mt-4"
                (click)="addressForms.removeAt(i)">Remove
                Address</button>
            </div>
          </div>

        </div>
        <button type="button" class="btn btn-primary btn-sm ms-auto py-2 px-4 mt-4" (click)="addAddress()">Add
          Adress</button>
      </div>
    </div>

    <div class="row">
      <h3 class="m-4">Phones</h3>
      <!-- Phones -->
      <div formArrayName="phones">
        <div *ngFor="let phone of phoneForms.controls; let i = index" [formGroupName]="i"
          class="border p-3 mb-1">
          <h5>Phone {{ i + 1 }}</h5>
          <!-- Contact Field -->
          <div class="mb-1">
            <label for="contact" class="form-label">Contact</label>
            <input type="text" id="contact" name="contact" class="form-control form-control-sm"
              formControlName="contact" required>
            <div *ngIf="phone.get('contact')?.invalid && phone.get('contact')?.touched" class="text-danger">
              Contact is required.
            </div>
          </div>
          <!-- Phone Number Field -->
          <div class="mb-1">
            <label for="phoneNumber" class="form-label">Phone Number</label>
            <input type="text" id="phoneNumber" name="phoneNumber" class="form-control form-control-sm"
              formControlName="phoneNumber" required>
            <div *ngIf="phone.get('phoneNumber')?.invalid && phone.get('phoneNumber')?.touched" class="text-danger">
              Phone number is required.
            </div>
          </div>
          <!-- Type Field -->
          <div class="mb-1">
            <label for="type" class="form-label">Type</label>
            <select id="typePhone" name="type" class="form-control form-control-sm form-select" formControlName="type"
              required>
              <option value="">Select Type</option>
              <option value="MOBILE">MOBILE</option>
              <option value="FIXED">FIXED</option>
              <option value="EMERGENCY1">EMERGENCY 1</option>
              <option value="EMERGENCY2">EMERGENCY 2</option>
              <option value="EMERGENCY3">EMERGENCY 3</option>
            </select>
            <div *ngIf="phone.get('type')?.invalid && phone.get('type')?.touched" class="text-danger">
              <div *ngIf="phone.get('type')?.errors?.['required']">Type is required.</div>
              <div *ngIf="phone.get('type')?.errors?.['pattern']">Invalid type.</div>
            </div>
          </div>
          <div class="d-flex">
            <button type="button" class="btn btn-danger btn-sm ms-auto py-2 px-4 mt-4" (click)="phoneForms.removeAt(i)">Remove
            Phone</button>
          </div>
        </div>
        <button type="button" class="btn btn-primary btn-sm ms-auto py-2 px-4 mt-4" (click)="addPhone()">Add Phone</button>
      </div>
    </div>


    <div class="form-footer d-flex gap-3">
      <button type="submit" class="btn btn-primary btn-sm ms-auto py-2 px-4">Submit</button>
      <button type="button" class="btn btn-secondary btn-sm py-2 px-4" (click)="cancelSubmit()">Cancel</button>
    </div>
  </form>
</div>


