import { Component } from '@angular/core';
import { RouterOutlet,Router, RouterModule } from '@angular/router';
import { MenuComponent } from './component/menu/menu.component';
import { SideBarMenuComponent } from '@components/menu/sidebar-menu/sidebar-menu.component'
import { LoginService } from './service/login/login.service';
import { AuthApiService } from './service/api/auth.service';
import { AlertComponent } from './component/alert/alert.component';
import { FooterComponent } from '@components/footer/footer.component';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,RouterModule, MenuComponent, SideBarMenuComponent, AlertComponent,FooterComponent ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Timo';

  constructor(private loginService:LoginService,private authServiceApi: AuthApiService, private router: Router) {
    this.loginService.checkCanLogin(refreshToken=>{
      this.authServiceApi.postAuthToken(refreshToken)
      .subscribe( value =>{
          this.loginService.setData(value)
      })
    })
    
	}
 
  ngOnInit(): void {
    this.loginService.loginEvent().subscribe((data) => {
      console.log("data.user?.roles", data.user?.roles)
      if (data.user?.roles === 'MANAGER'){
        this.router.navigate(['/dashboard/manager']);
      } else if (data.user?.roles === 'EMPLOYEE') {
        this.router.navigate(['/dashboard/employee']);
      }
    });
  }

  login(){
    this.authServiceApi.postAuthLogin({email:'admin',password:'password'})
    .subscribe( value =>{
      this.loginService.setData(value)
  })
  }
  logout(){
    this.loginService.clearTokens()
  }
}
