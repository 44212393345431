<div class="container-fluid mt-4 p-4">
  <form class="form-container" [formGroup]="userForm" (ngSubmit)="onSubmit()">

    <div class="row">
      <h3>User Name</h3>
      <!-- First Name Field -->
      <div class="mb-1 col-md-6">
        <app-label [forAttribute]="'firstName'" [labelText]="'First Name'" [isRequired]="true"></app-label>
        <input type="text" id="firstName" name="firstName" class="form-control form-control-sm"
          formControlName="firstName" required>
        <div *ngIf="userForm.get('firstName')?.invalid && userForm.get('firstName')?.touched" class="text-danger">
          First name is required.
        </div>
      </div>
      <!-- Last Name Field -->
      <div class="mb-1 col-md-6">
        <app-label [forAttribute]="'lastName'" [labelText]="'Last Name'" [isRequired]="true"></app-label>
        <input type="text" id="lastName" name="lastName" class="form-control form-control-sm" formControlName="lastName"
          required>
        <div *ngIf="userForm.get('lastName')?.invalid && userForm.get('lastName')?.touched" class="text-danger">
          Last name is required.
        </div>
      </div>
    </div>

    <div class="row">
      <h3>Login Data</h3>
      <!-- Email Field -->
      <div class="mb-1 col-md-6">
        <app-label [forAttribute]="'email'" [labelText]="'Email'" [isRequired]="true"></app-label>
        <input type="email" id="email" name="email" class="form-control form-control-sm" formControlName="email" required>
        <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched" class="text-danger">
          <div *ngIf="userForm.get('email')?.errors?.['required']">Email is required.</div>
          <div *ngIf="userForm.get('email')?.errors?.['email']">Invalid email format.</div>
          <div *ngIf="userForm.get('email')?.errors?.['emailInUse']">{{ errorMessages['email'] }}</div>
        </div>
      </div>
      <div class="mb-1 form-check col-md-6">
        <label for="emailConfirmed" class="form-check-label">Email Confirmed</label>
        <input type="checkbox" id="emailConfirmed" class="form-check-input" formControlName="emailConfirmed">

      </div>
      <!-- Password Field -->
      <!-- <div class="mb-1 col-md-6">
        <label for="password" class="form-label">Password</label>
        <input type="password" id="password" name="password" class="form-control form-control-sm"
          formControlName="password" required>
        <div *ngIf="userForm.get('password')?.invalid && userForm.get('password')?.touched" class="text-danger">
          Password is required.
        </div>
      </div> -->
      <div class="mb-1 col-md-6">
        <app-label [forAttribute]="'password'" [labelText]="'Password'" [isRequired]="false"></app-label>
        <div class="password-container">
          <input [type]="hidePassword ? 'password' : 'text'" id="password" name="password"
            class="form-control form-control-sm" formControlName="password">
          <div class="password-icon">
            <span class="input-group-text" (click)="hidePassword = !hidePassword">
              <mat-icon class="material-icons-outlined">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </span>
          </div>
        </div>

      </div>

      <div class="mb-1 col-md-6">
        <label for="confirmPassword" class="form-label">Confirm Password</label>
        <div class="password-container">
          <input [type]="hideConfirmPassword ? 'password' : 'text'" id="confirmPassword" name="confirmPassword"
            class="form-control form-control-sm" required>
          <div class="password-icon">
            <span class="input-group-text " (click)="hideConfirmPassword = !hideConfirmPassword">
              <mat-icon
                class="material-icons-outlined">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </span>
          </div>
        </div>
        <div *ngIf="userForm.get('confirmPassword')?.invalid && userForm.get('confirmPassword')?.touched"
          class="text-danger">
          Confirm Password is required.
        </div>
      </div>
    </div>
    <div class="row">
      <h3>Contract Details</h3>
      <!-- Roles Field -->

      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'roles'" [labelText]="'Roles'" [isRequired]="true"></app-label>
        <select id="roles" name="roles" class="form-control form-control-sm form-select" formControlName="roles" required>
          <option value="">Select Role</option>
          <option value="MANAGER">MANAGER</option>
          <option value="EMPLOYEE">EMPLOYEE</option>
        </select>
        <div *ngIf="userForm.get('roles')?.invalid && userForm.get('roles')?.touched" class="text-danger">
          Role is required.
        </div>
      </div>
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'contractType'" [labelText]="'Contract Type'" [isRequired]="true"></app-label>
        <select id="contractType" name="contractType" class="form-control form-control-sm form-select"
          formControlName="contractType" required>
          <option value="">Select Contract Type</option>
          <option value="FULL_TIME">FULL TIME</option>
          <option value="PART_TIME">PART TIME</option>
          <option value="FIXED_TERM">FIXED TERM</option>
          <option value="PERMANENT">PERMANENT</option>
          <option value="REMOTE_WORK">REMOTE WORK</option>
          <option value="HOURLY">HOURLY</option>
          <option value="FREELANCE">FREELANCE</option>
          <option value="CONSULTING">CONSULTING</option>
          <option value="VOLUNTEER">VOLUNTEER</option>
          <option value="TEMPORARY">TEMPORARY</option>
          <option value="ZERO_HOUR">ZERO HOUR</option>
        </select>
        <div *ngIf="userForm.get('contractType')?.invalid && userForm.get('contractType')?.touched" class="text-danger">
          Contract type is required.
        </div>
      </div>
      <!-- Occupation Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'occupation'" [labelText]="'Occupation'" [isRequired]="true"></app-label>
        <input type="text" id="occupation" class="form-control" formControlName="occupation" required>
        <div *ngIf="userForm.get('occupation')?.invalid && userForm.get('occupation')?.touched" class="text-danger">
          Occupation is required.
        </div>
      </div>


      <!-- Start Date Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'startDate'" [labelText]="'Start Date'" [isRequired]="true"></app-label>
        <input type="date" id="startDate" name="startDate" class="form-control form-control-sm"
          formControlName="startDate" required>
        <div *ngIf="userForm.get('startDate')?.invalid && userForm.get('startDate')?.touched" class="text-danger">
          Start date is required.
        </div>
      </div>

      <!-- Weekly Working Hours Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'weeklyWorkingHours'" [labelText]="' Weekly Working Hours'" [isRequired]="true"></app-label>
        <input type="number" id="weeklyWorkingHours" class="form-control" formControlName="weeklyWorkingHours" required>
        <div *ngIf="userForm.get('weeklyWorkingHours')?.invalid && userForm.get('weeklyWorkingHours')?.touched" class="text-danger">
          Weekly Working Hours is required.
        </div>
      </div>
      <!-- Vacation Entitlement Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'vacationEntitlement'" [labelText]="'Vacation Entitlement'" [isRequired]="true"></app-label>
        <input type="number" id="vacationEntitlement" class="form-control" formControlName="vacationEntitlement"
          required>
          <div *ngIf="userForm.get('vacationEntitlement')?.invalid && userForm.get('vacationEntitlements')?.touched" class="text-danger">
            Vacation Entitlement is required.
          </div>
      </div>

    </div>

    <div class="row">

      <h3>Personal Data</h3>
      <!-- Personal Number Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'personalNumber'" [labelText]="'Personal Number'" [isRequired]="true"></app-label>
        <input type="text" id="personalNumber" class="form-control" formControlName="personalNumber" required>
        <div *ngIf="userForm.get('personalNumber')?.invalid && userForm.get('personalNumber')?.touched" class="text-danger">
          Personal Number is required.
        </div>
      </div>

      <!-- Social Security Number Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'socialSecurityNumber'" [labelText]="'Social Security Number'" [isRequired]="true"></app-label>
        <input type="text" id="socialSecurityNumber" class="form-control" formControlName="socialSecurityNumber"
          required>
          <div *ngIf="userForm.get('socialSecurityNumber')?.invalid && userForm.get('socialSecurityNumber')?.touched" class="text-danger">
            Social Security Number is required.
          </div>
      </div>



      <!-- Severe Disability Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'severeDisability'" [labelText]="'Severe Disability'" [isRequired]="true"></app-label>
        <input type="text" id="severeDisability" class="form-control" formControlName="severeDisability" required>
        <div *ngIf="userForm.get('severeDisability')?.invalid && userForm.get('severeDisability')?.touched" class="text-danger">
          Severe Disability is required.
        </div>
      </div>

      <!-- Employment Status Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'employmentStatus'" [labelText]="'Employment Status'" [isRequired]="true"></app-label>
        <input type="text" id="employmentStatus" class="form-control" formControlName="employmentStatus" required>
        <div *ngIf="userForm.get('employmentStatus')?.invalid && userForm.get('employmentStatus')?.touched" class="text-danger">
          Employment Status is required.
        </div>
      </div>

      <!-- Type Of Workplace Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'typeOfWorkplace'" [labelText]="'Type Of Workplace'" [isRequired]="true"></app-label>
        <input type="text" id="typeOfWorkplace" class="form-control" formControlName="typeOfWorkplace" required>
        <div *ngIf="userForm.get('typeOfWorkplace')?.invalid && userForm.get('typeOfWorkplace')?.touched" class="text-danger">
          Type Of Workplace is required.
        </div>
      </div>

      <!-- IBAN Field -->
      <div class="mb-1 col-md-4">
        
        <app-label [forAttribute]="'iban'" [labelText]="'IBAN'" [isRequired]="true"></app-label>
        <input type="text" id="iban" name="iban" class="form-control form-control-sm" formControlName="iban" required>
        <div *ngIf="userForm.get('iban')?.invalid && userForm.get('iban')?.touched" class="text-danger">
          IBAN is required.
          <div *ngIf="userForm.get('iban')?.errors?.['pattern']">Invalid IBAN format.</div>
        </div>
      </div>
      <!-- Private Email Field -->
      <div class="mb-1">
        <label for="privateEmail" class="form-label">Private Email</label>
        <input type="email" id="privateEmail" class="form-control" formControlName="privateEmail">
        <div *ngIf="userForm.get('privateEmail')?.invalid && userForm.get('privateEmail')?.touched" class="text-danger">
          Private Email is required.
        </div>
      </div>
      <!-- Birthday Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'birthday'" [labelText]="'Birthday'" [isRequired]="true"></app-label>
        <input type="date" id="birthday" name="birthday" class="form-control form-control-sm" formControlName="birthday"
          required>
        <div *ngIf="userForm.get('birthday')?.invalid && userForm.get('birthday')?.touched" class="text-danger">
          Birthday is required.
        </div>
      </div>
      <!-- Gender Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'gender'" [labelText]="'Gender'" [isRequired]="true"></app-label>
        <input type="text" id="gender" name="gender" class="form-control form-control-sm" formControlName="gender"
          required>
        <div *ngIf="userForm.get('gender')?.invalid && userForm.get('gender')?.touched" class="text-danger">
          Gender is required.
        </div>
      </div>

      <!-- Marital Status Field -->
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'maritalStatus'" [labelText]="'Marital Status'" [isRequired]="true"></app-label>
        <input type="text" id="maritalStatus" class="form-control" formControlName="maritalStatus" required>
        <div *ngIf="userForm.get('maritalStatus')?.invalid && userForm.get('maritalStatus')?.touched" class="text-danger">
          Marital Status is required.
        </div>
      </div>
      <div class="mb-1 col-md-4">
        <app-label [forAttribute]="'parentalStatus'" [labelText]="'Parental Status'" [isRequired]="true"></app-label>
        <select id="parentalStatus" class="form-select" formControlName="parentalStatus" required="">
          <option value="">Select Status</option>
          <option value="no">No</option>
          <option value="yes">Yes</option>
          <div *ngIf="userForm.get('parentalStatus')?.invalid && userForm.get('parentalStatus')?.touched" class="text-danger">
            Parental Status type is required.
          </div>
        </select>
        
      </div>
      <div *ngIf="userForm.get('parentalStatus')?.value === 'yes'" class="mb-1 form-check col-md-12" style="margin-left: 1rem;">
        <input type="checkbox" id="singleParent" class="form-check-input" formControlName="singleParent">
        <label for="singleParent" class="form-check-label">Single Parent</label>
      </div>
      <div *ngIf="userForm.get('parentalStatus')?.value === 'yes'" formArrayName="children">
         <!-- Single Parent Field -->
     
        <div *ngFor="let child of childrenForms.controls; let i = index" [formGroupName]="i" class="row border p-3 mb-1 mt-4">
          <h5>Child {{ i + 1 }}</h5>
          <!-- Name Field -->
          <div class="mb-1 col-md-6">
            <app-label [forAttribute]="'childName' + i" [labelText]="'Name'" [isRequired]="true"></app-label>
            <input type="text" [id]="'childName' + i" [name]="'childName' + i" class="form-control form-control-sm" formControlName="name">
            <div *ngIf="child.get('name')?.invalid && child.get('name')?.touched" class="text-danger">
              Name is required.
            </div>
          </div>
          <!-- Birthday Field -->
          <div class="mb-1 col-md-6">
            <app-label [forAttribute]="'childBirthday' + i" [labelText]="'Birthday'" [isRequired]="true"></app-label>
            <input type="date" [id]="'childBirthday' + i" [name]="'childBirthday' + i" class="form-control form-control-sm" formControlName="birthday">
            <div *ngIf="child.get('birthday')?.invalid && child.get('birthday')?.touched" class="text-danger">
              Birthday is required.
            </div>
          </div>
          
            <div class="d-flex">
          <button type="button" class="btn btn-danger btn-sm ms-auto py-2 px-4" style="width: 200px;" (click)="removeChild(i)">Remove Child</button>
        </div>
        </div>
        <button type="button" class="btn btn-primary btn-sm ms-auto py-2 px-4 mt-4" (click)="addChild()">Add Child</button>
      </div>

     
    </div>

    <div class="row">
      <h3>Education</h3>

      <!-- Highest Professional Education Field -->
      <div class="mb-1">
        <app-label [forAttribute]="'highestProfessionalEducation'" [labelText]="'Highest Professional Education'" [isRequired]="true"></app-label>
        <input type="text" id="highestProfessionalEducation" class="form-control"
          formControlName="highestProfessionalEducation" required>
          <div *ngIf="userForm.get('highestProfessionalEducation')?.invalid && userForm.get('highestProfessionalEducation')?.touched" class="text-danger">
            Highest Professional Education is required.
          </div>
      </div>

      <!-- School Leaving Certificate Field -->
      <div class="mb-1">
        <app-label [forAttribute]="'schoolLeavingCertificate'" [labelText]="'School Leaving Certificate'" [isRequired]="true"></app-label>
        <input type="text" id="schoolLeavingCertificate" class="form-control"
          formControlName="schoolLeavingCertificate">
          <div *ngIf="userForm.get('schoolLeavingCertificate')?.invalid && userForm.get('schoolLeavingCertificate')?.touched" class="text-danger">
            School Leaving Certificate is required.
          </div>
      </div>

    </div>

    <div class="row">
      <h3 >Address</h3>
      <!-- Addresses -->
      <div formArrayName="address">
        <div *ngFor="let address of addressForms.controls; let i = index" [formGroupName]="i" class="p-3 mb-1">

          <div class="row">
            <h5>Address {{ i + 1 }}</h5> 
          <!-- Type Field -->
          <div class="mb-1 col-md-4">
            <app-label [forAttribute]="'addressType'" [labelText]="'Address Type'" [isRequired]="true"></app-label>
            <input type="text" id="addressType" name="addressType" class="form-control form-control-sm" formControlName="type"
              required>
            <div *ngIf="address.get('type')?.invalid && address.get('type')?.touched" class="text-danger">
              Address Type is required.
            </div>
          </div>
          <!-- Country Field -->
          <div class="mb-1 col-md-4">
            <app-label [forAttribute]="'country'" [labelText]="'Country'" [isRequired]="true"></app-label>
            <select id="country" name="country" class="form-control form-control-sm form-select" formControlName="country" required>
              <option value="">Select Country</option>
              <!-- Adaugă toate opțiunile de țări din enum -->
              <option *ngFor="let country of countryList" [value]="country">{{ country }}</option>
            </select>
            <div *ngIf="address.get('country')?.invalid && address.get('country')?.touched" class="text-danger">
              <div *ngIf="address.get('country')?.errors?.['required']">Country is required.</div>
              <div *ngIf="address.get('country')?.errors?.['pattern']">Invalid country.</div>
            </div>
          </div>
       
          <!-- City Field -->
          <div class="mb-1 col-md-4">
            <app-label [forAttribute]="'city'" [labelText]="'City'" [isRequired]="true"></app-label>
            <input type="text" id="city" name="city" class="form-control form-control-sm" formControlName="city"
              required>
            <div *ngIf="address.get('city')?.invalid && address.get('city')?.touched" class="text-danger">
              City is required.
            </div>
          </div>
          
          <!-- Street Field -->
          <div class="mb-1 col-md-12">
            <app-label [forAttribute]="'street'" [labelText]="'Street'" [isRequired]="true"></app-label>
            <input type="text" id="street" name="street" class="form-control form-control-sm" formControlName="street"
              required>
            <div *ngIf="address.get('street')?.invalid && address.get('street')?.touched" class="text-danger">
              Street is required.
            </div>
          </div>
          <!-- Postal Code Field -->
          <div class="mb-1 col-md-4">
            <app-label [forAttribute]="'postalCode'" [labelText]="'Postal Code'" [isRequired]="true"></app-label>
            <input type="text" id="postalCode" name="postalCode" class="form-control form-control-sm"
              formControlName="postalCode" required>
            <div *ngIf="address.get('postalCode')?.invalid && address.get('postalCode')?.touched" class="text-danger">
              Postal code is required.
            </div>
          </div>
          <div class="d-flex">
            <button type="button" class="btn btn-danger btn-sm ms-auto py-2 px-4 mt-4"
              (click)="addressForms.removeAt(i)">Remove
              Address</button>
            </div>
        </div>
        
        </div>
        <button type="button" class="btn btn-primary btn-sm ms-auto py-2 px-4 mt-4" (click)="addAddress()">Add
          Adress</button>
      </div>
    </div>
    <div class="row">
      <h3 class="m-4">Phones</h3>
      <!-- Phones -->
      <div formArrayName="phones">
        <div *ngFor="let phone of phoneForms.controls; let i = index" [formGroupName]="i" class="border p-3 mb-1">
          <h5>Phone {{ i + 1 }}</h5>
          <!-- Contact Field -->
          <div class="mb-1">
            <app-label [forAttribute]="'contact'" [labelText]="'Contact'" [isRequired]="true"></app-label>
            <input type="text" id="contact" name="contact" class="form-control form-control-sm"
              formControlName="contact" required>
            <div *ngIf="phone.get('contact')?.invalid && phone.get('contact')?.touched" class="text-danger">
              Contact is required.
            </div>
          </div>
          <!-- Phone Number Field -->
          <div class="mb-1">
            <app-label [forAttribute]="'phoneNumber'" [labelText]="'Phone Number'" [isRequired]="true"></app-label>
            <input type="text" id="phoneNumber" name="phoneNumber" class="form-control form-control-sm"
              formControlName="phoneNumber" required>
            <div *ngIf="phone.get('phoneNumber')?.invalid && phone.get('phoneNumber')?.touched" class="text-danger">
              Phone number is required.
            </div>
          </div>
          <!-- Type Field -->
          <div class="mb-1">
            <app-label [forAttribute]="'phoneType'" [labelText]="'Phone Type'" [isRequired]="true"></app-label>
            <select id="phoneType" name="phoneType" class="form-control form-control-sm form-select" formControlName="type" required>
              <option value="">Select Type</option>
              <option value="MOBILE">MOBILE</option>
              <option value="FIXED">FIXED</option>
              <option value="EMERGENCY1">EMERGENCY 1</option>
              <option value="EMERGENCY2">EMERGENCY 2</option>
              <option value="EMERGENCY3">EMERGENCY 3</option>
            </select>
            <div *ngIf="phone.get('type')?.invalid && phone.get('type')?.touched" class="text-danger">
              <div *ngIf="phone.get('type')?.errors?.['required']">Type is required.</div>
              <div *ngIf="phone.get('type')?.errors?.['pattern']">Invalid type.</div>
            </div>
          </div>
          <div class="d-flex">
          <button type="button" class="btn btn-danger btn-sm ms-auto py-2 px-4 mt-4" (click)="phoneForms.removeAt(i)">Remove
            Phone</button>
          </div>
        </div>
        <button type="button" class="btn btn-primary btn-sm ms-auto py-2 px-4 mt-4" (click)="addPhone()">Add Phone</button>
      </div>
    </div>


    <div class="form-footer d-flex gap-3">
      <button type="submit" class="btn btn-primary btn-sm ms-auto py-2 px-4">Submit</button>
      <button type="button" class="btn btn-secondary btn-sm py-2 px-4" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>