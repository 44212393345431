<div class="container justify-content-center align-items-center">
  <form (ngSubmit)="onSubmit()" [formGroup]="reportsForm">

    <div class="row justify-content-md-center mt-5">

      <div class="col-auto">
        <label for="reportType" class="form-label">Report type</label>
        <select class="form-select" aria-label="Default select example" id="reportType" formControlName="reportType">
          <option value="null" selected disabled>Pick report type</option>
          <option [value]="reportType.value" *ngFor="let reportType of reportTypes">{{reportType.name}}</option>
        </select>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-2 " *ngIf="reportsForm.value.reportType !== 'attendance'">
        <label for="year" class="form-label">Year</label>
        <input type="number" class="form-control" id="year" formControlName="year">
      </div>
      <div class="col-auto" *ngIf="reportsForm.value.reportType === 'attendance'">
        <label for="startDate" class="form-label">Start Date</label>
        <input type="date" class="form-control" id="startDate" formControlName="startDate">
      </div>
      <div class="col-auto" *ngIf="reportsForm.value.reportType === 'attendance'">
        <label for="endDate" class="form-label">End Date</label>
        <input type="date" class="form-control" id="endDate" formControlName="endDate">
      </div>
    </div>
    <div class="row justify-content-md-center mt-5">
      <div class="col-auto">
        <label for="user" class="form-label">Report type</label>
        <select class="form-select" aria-label="Default select example" id="users" formControlName="user">
          <option [value]="null" selected>All users</option>
          <option [value]="user.id" *ngFor="let user of users"> {{user.firstName}} {{user.lastName}}</option>
        </select>
      </div>
      <div class="col-auto">
        <label for="inputPassword2" class="form-label">Download report</label>
        <button type="submit" class="form-control btn btn-primary ">Download</button>
      </div>
    </div>

  </form>
</div>