<div class="container fixed-top mt-2">
    <div class="row justify-content-center">
      <div class="col-10 col-sm-10 col-md-8 col-lg-4 col-xl-4">
       
        <div class="alert alert-success alert-dismissible fade show" [ngClass]="toClass(alert.classType)" *ngFor="let alert of alerts;">
            <strong>{{alert.type.toString()}}!</strong> {{ alert.message }}
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        </div>
        
      </div>
    </div>
</div>

