import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OfficeDTO } from '@model/officeDTO';
import { OfficeApiService } from '@api/office.service';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';

@Component({
  selector: 'app-add-user-to-office',
  standalone: true,
  imports: [CommonModule, AlertComponent],
  templateUrl: './add-user-to-office.component.html',
  styleUrl: './add-user-to-office.component.css',
})
export class AddUserToOfficeComponent {
  offices: Array<OfficeDTO> = [];
  userId: number = 0;
  selectedOfficeId: number | undefined;
  isNewMember: boolean = true;
  memberName:string = "";
  constructor(
    private officeApi: OfficeApiService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.getOffices();
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.userId = id;

      this.route.queryParams.subscribe(queryParams => {
        console.log("queryParams['key']", queryParams['key']);
        console.log("queryParams['name']", queryParams['name']);
        if (queryParams['key'] !== "newMember") {
          this.isNewMember = false;
        }
        if (queryParams['name']) {
          this.memberName = queryParams['name'];
        }
      });
    });
    
  }

  getOffices() {
    this.officeApi.getOffice({ pageSize: 100 }).subscribe((value) => {
      
      this.offices = value.content;
      this.selectedOfficeId = this.offices[0].id;
    });
  }
  onOfficeSelectionChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    if (value) {
      this.selectedOfficeId = parseInt(value, 10);
    } else {
      this.selectedOfficeId = this.offices[0].id;
    }
  }
  addUserToOffice() {
    if (this.selectedOfficeId && this.userId !== undefined) {
      const userId = this.userId;
      const ids: number[] = [userId];

      this.officeApi
        .postOfficeUser(this.selectedOfficeId, { userIds: ids })
        .subscribe(() => {
          console.log('User Added to Office');
          this.alertService.sendAlertSuccess(
            'The user was added to the office'
          );
        });
    }
  }
}
