<div class="container-fluid mt-4">
  <div class="row justify-content-center col-md-8 bg-white ms-auto p-4 rounded">
    <div class="col-md-8 m-4" *ngIf="!registrationSuccessful">
      <h2 class="m-4 text-primary text-center" >Welcome to the Registration Page!</h2>
      <form (ngSubmit)="onSubmit()" [formGroup]="registerForm">
        <div class="mb-3">
          <label for="firstName" class="form-label">First Name</label>
          <input type="text" class="form-control" id="firstName" formControlName="firstName">
          <div *ngIf="registerForm.get('firstName')?.invalid && registerForm.get('firstName')?.touched" class="text-danger">
            First Name is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="lastName" class="form-label">Last Name</label>
          <input type="text" class="form-control" id="lastName" formControlName="lastName">
          <div *ngIf="registerForm.get('lastName')?.invalid && registerForm.get('lastName')?.touched" class="text-danger">
            Last Name is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input type="email" class="form-control" id="email" formControlName="email">
          <div *ngIf="registerForm.get('email')?.invalid && registerForm.get('email')?.touched" class="text-danger">
            Valid Email is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" formControlName="password">
          <div *ngIf="registerForm.get('password')?.invalid && registerForm.get('password')?.touched" class="text-danger">
            Password is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="companyName" class="form-label">Company Name</label>
          <input type="text" class="form-control" id="companyName" formControlName="companyName">
          <div *ngIf="registerForm.get('companyName')?.invalid && registerForm.get('companyName')?.touched" class="text-danger">
            Company Name is required.
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Register</button>
      </form>
    </div>
    <div class="row text-center col-md-8 bg-white p-4 rounded" *ngIf="registrationSuccessful">
      <h2 class="text-primary m-4" >Congratulations!</h2>
      <p>Your account has been created successfully.</p>
      <p>A confirmation email has been sent to <strong>{{ email }}</strong>.</p>
      <p>Please confirm your email to log in.</p>
      <button (click)="backToRegister()" class="btn btn-primary">Back to Register Page</button>
    </div>
  </div>
</div>
