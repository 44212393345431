import { Component,Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule } from '@angular/forms';
import { UserDTO } from '@model/userDTO';
import { UsersApiService } from '@api/users.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-update-user',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './update-user.component.html',
  styleUrl: './update-user.component.css',
})
export class UpdateUserComponent {
  constructor(private fb: FormBuilder, private userApi: UsersApiService, private titleService: Title) {}

  @Input() user: UserDTO | any = [];
  @Output() changeView = new EventEmitter<string>();
  ngOnInit(): void {
    console.log('Component is initialized, making an API call...');
    this.titleService.setTitle(`Update User Info`, );
    
  }

  update(
    id: number,
    firstName: string,
    lastName: string,
    password: string | undefined,
    email: string,
    confirmedEmail: boolean,
    roles: string | any
  ) {
    var pass = password;

    // if (!isPasswordValid(password)) {
    //   pass = undefined;
    // }

    this.userApi
      .patchUsers(id, {
        email,
        firstName,
        lastName,
        emailConfirmed: confirmedEmail,
        password: pass,
        roles: roles,
      })
      .subscribe(() => {
        console.log('user updated')
        this.changeView.emit('get-users');
      });
  }

  cancelSubmit() {
    console.log('cancel');
    this.changeView.emit('get-users');
  }
}
