import { CommonModule } from '@angular/common';
import { Component, ViewChild, Input } from '@angular/core';

import { CreateUserComponent } from '@components/users/user/create-user/create-user.component';
import { GetUsersComponent } from '@components/users/get-users/get-users.component';
import { UpdateUserComponent } from '@components/users/user/update-user/update-user.component';
import { AddUserToOfficeComponent } from '@components/offices/office/add-user-to-office/add-user-to-office.component';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { ManagementHeaderComponent } from '@components/menu/management-header/management-header.component';
import { RouterModule } from '@angular/router';
import { AlertComponent } from '@components/alert/alert.component';
import { UserDTO } from '@model/userDTO';

@Component({
  selector: 'app-users-management',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AlertComponent,
    CreateUserComponent,
    GetUsersComponent,
    ModalComponent,
    ManagementHeaderComponent,
    UpdateUserComponent,
    AddUserToOfficeComponent,
  ],
  templateUrl: './users-management.component.html',
  styleUrl: './users-management.component.css',
})
export class UsersManagementComponent {
  @Input() selectedUser: UserDTO | undefined;
  @ViewChild('getUsersComponent') getUsersComponent?:
    | GetUsersComponent
    | undefined;
  currentView: string = 'get-users';
  userId: number = 0;
  showCreateUser: boolean = true;

  constructor() {
    this.getUsersComponent = undefined;
  }
  setCurrentView(value: string) {
    this.currentView = value;
  }
  setUserId(value: number) {
    this.userId = value;
  }
  setSelectedUser(user: UserDTO) {
    this.selectedUser = user;
  }
  handleUserCreated() {
    if (this.getUsersComponent) {
      this.getUsersComponent.getUsers();
      this.showCreateUser = false;
    } else {
      console.error('getUsersComponent is undefined!');
    }
  }
}
