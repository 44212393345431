import { Route } from '@angular/router';
import { adminGuard } from '@core/guards/admin.guards';
import { OfficeManagementComponent } from './office-management.component';
import { EditOfficeComponent } from './office/edit-office/edit-office.component';
import { EditOfficeUsersComponent } from './office/edit-office-users/edit-office-users.component';
import { CreateOfficeComponent } from './office/create-office/create-office.component';

export const OFFICE_MANAGEMENT_ROUTES: Route[] = [
  { path: '', component: OfficeManagementComponent, canActivate: [adminGuard] },
  { path: 'office/:id/edit', component: EditOfficeComponent, canActivate: [adminGuard] },
  { path: 'office/:id/users', component: EditOfficeUsersComponent, canActivate: [adminGuard] },
  { path: 'create', component: CreateOfficeComponent, canActivate: [adminGuard] },
];
