<app-modal [modalId]="'attendance'" title="Add new Leave" [isForm]="true">
  <div class="container-fluid mt-4">
    <div *ngIf="!isEntered && attendanceInId == 0" class="mt-4">
      <h4>Enter Attendance</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="mb-3">
            <label for="inputDate" class="form-label">Date In:</label>
            <input type="datetime-local" class="form-control" id="inputDate" [(ngModel)]="currentDateTime">
          </div>
        </div>
        <div class="col-md-4">
          <div class="md-3">
            <label for="noteInput" class="form-label">Note:</label>
            <textarea class="form-control" id="noteInput" #noteInput rows="1"></textarea>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary" (click)="enter(noteInput.value)">In</button>
    </div>

    <div *ngIf="attendanceInId > 0" class="mt-4">
      <h4>Exit Attendance </h4>
      <h5 *ngIf="attendanceWithoutOut > 2">You have {{ attendanceWithoutOut }} attendance which
        requires your attention</h5>
      <div class="row">
        <div class="col-md-4">
          <div class="mb-3">
            <label for="inputDate" class="form-label">Date Out:</label>
            <input type="datetime-local" class="form-control" id="inputDate" [(ngModel)]="currentDateTime">
          </div>
        </div>
        <div class="col-md-4">
          <div class="md-3">
            <label for="noteInput" class="form-label">Note:</label>
            <textarea class="form-control" id="noteInput" #noteInput rows="1"></textarea>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-danger" (click)="exit(attendanceInId, noteInput.value)">Out</button>
    </div>
  </div>
</app-modal>

<app-management-header headTitle="Attendance"  modalId="attendance" actionButtonText="Add Atendance"></app-management-header>


<div class="mt-4 mb-4">
  <div class="mb-4 col-md-4">
    <label for="periodSelect" class="form-label">Select Period:</label>
    <select id="periodSelect" class="form-select" (change)="onPeriodChange($event)" [(ngModel)]="selectedPeriod">
      <option value="TODAY">Today</option>
      <option value="THIS_WEEK">This Week</option>
      <option value="LAST_WEEK">Last Week</option>
      <option value="THIS_MONTH">This Month</option>
      <option value="LAST_MONTH">Last Month</option>
      <option value="PERIOD">Custom Period</option>
    </select>
  </div>
  
  <div *ngIf="customPeriod">
    <div class="row">
    <div class="mb-1 col-md-4">
    <label for="startDate" class="form-label">Start Date:</label>
    <input type="date" id="startDate" class="form-control form-control-sm" [(ngModel)]="startDate">
  </div>
  <div class="mb-1 col-md-4">
    <label for="endDate" class="form-label">End Date:</label>
    <input type="date" id="endDate" class="form-control form-control-sm" [(ngModel)]="endDate">
  </div>
    <button (click)="applyCustomPeriod()">Apply</button>
  </div>
</div>
  <ul class="list-group">
    <li *ngFor="let record of attendanceList; let i = index" class="list-group-item">
      
      <div class="ms-2 me-auto d-flex justify-content-between align-items-start">
        <div>TimeIn: {{ record.timeIn }}</div>
        <div>TimeOut: {{ record.timeOut | date: 'medium' }}</div>
        <div class="progress">
          <div class="progress-bar" role="progressbar" style="width: 200px;" aria-valuemin="0" aria-valuemax="8">
            {{record.hours === null || record.hours === undefined ? 0 : record.hours.toFixed(2)}}
          </div>
        </div>
        <div *ngIf="record.note === undefined">Note: {{ record.note }}</div>
        <form [formGroup]="attendanceForm" *ngIf="record.hours === null" (ngSubmit)="onSubmit(record.id)">
          <div class="mt-3">
            <h5>Exit Attendance</h5>
            <div class="row">
              <div class="col">
                <div>
                  <label for="exitDate" class="form-label">DateTime Input:</label>
                  <input type="datetime-local" class="form-control form-control-sm" id="exitDate"
                    formControlName="currentDateTime" [ngModel]="record.timeIn ? record.timeIn : null">
                </div>
              </div>
              <div class="col">
                <div>
                  <label for="exitNoteInput" class="form-label">Note:</label>
                  <textarea class="form-control form-control-sm" id="exitNoteInput" formControlName="comment"
                    [ngModel]="record.note"></textarea>
                </div>
              </div>
              <div class="col">
                <div>
                  <label for="exitNoteInput" class="form-label">Action:</label>
                  <br>
                  <button type="submit" class="btn btn-primary btn-sm">Out</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <button type="button" class="btn btn-danger btn-sm m-4" (click)="delete(record.id)">Delete</button>
    </li>
  </ul>

  
  
  
</div>
