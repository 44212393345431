import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { PublicHoliday } from '../model/publicHoliday';
import { CountryDTO } from '../model/countryDTO';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryApiService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) {}

  getCountryPublicHolidayCY(countryCode: string, year: number): Observable<Array<PublicHoliday>> {
    const url = this.baseUrl + '/country/public-holiday/'+countryCode+'/'+year+'';
    return this.http.get<any>(url );
  }

  getCountryPublicHolidayCYN(countryCode: string, year: number, numberOfYears: number): Observable<Array<PublicHoliday>> {
    const url = this.baseUrl + '/country/public-holiday/'+countryCode+'/'+year+'/'+numberOfYears+'';
    return this.http.get<any>(url );
  }

  getCountryList(): Observable<Array<CountryDTO>> {
    const url = this.baseUrl + '/country/list';
    return this.http.get<any>(url );
  }

}
