<header>
 
    <nav id="main-navbar" class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
                aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" routerLink="/home"><img src="/assets/logo.png" alt="image"></a>
            <div class="collapse navbar-collapse" id="navbarToggler">
                <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item" *ngFor="let menuItem of menuItems">
                        <a class="nav-link" [routerLink]="menuItem.link" *ngIf="isLogin">{{ menuItem.label }}</a>
                    </li>
                    <div class="btn-group" *ngIf="isAdmin">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            Manager menu
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                            <li *ngFor="let adminMenuItem of adminMenuItems"><a class="dropdown-item"
                                    [routerLink]="adminMenuItem.link">{{ adminMenuItem.label }}</a></li>

                        </ul>
                    </div>
                </ul> -->
                <form *ngIf="!isLogin" class="d-flex" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <input class="form-control me-2 form-control-sm" type="text" placeholder="Email" aria-label="Email"
                        formControlName="email">
                    <input class="form-control me-2 form-control-sm" type="password" placeholder="Password"
                        aria-label="Password" formControlName="password">
                    <button class="btn btn-outline-success btn-sm  px-4" type="submit">Login</button>
                </form>
                <ul *ngIf="isLogin" class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <span class="nav-link">Welcome, <span id="name">{{ user?.firstName }} {{ user?.lastName }}</span></span>
                    </li>
                    <li class="nav-item">
                        <button class="btn btn-outline-warning btn-sm mt-1" id="logout"
                            (click)="logout()">Logout</button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>