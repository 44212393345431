import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthApiService } from '@api/auth.service';
import { AlertService } from '@alert/alert.service';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  registerForm: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    companyName: ['', Validators.required]
  });

  email: string | null = null;
  registrationSuccessful = false;

  constructor(private fb: FormBuilder, private authApi: AuthApiService, private alert: AlertService) {}

  onSubmit() {
    console.log('Form submitted', this.registerForm);
    if (this.registerForm.valid) {
      console.log('Form is valid', this.registerForm.value);
      const { firstName, lastName, email, password, companyName } = this.registerForm.value;
      this.authApi.postAuthRegister({ firstName, lastName, email, password, companyName })
        .pipe(catchError(error => {
          this.alert.handleError(error, cb => {
            this.alert.sendAlertError(cb.message);
          });
          return [];
        }))
        .subscribe(() => {
          this.email = email;
          this.registrationSuccessful = true;
        });
    } else {
      console.log('Form is invalid', this.registerForm.errors);
      this.registerForm.markAllAsTouched(); // Mark all fields as touched to display validation errors
      this.scrollToFirstError();
      this.alert.sendAlertError('Some fields in the form are not valid');
    }
  }

  scrollToFirstError(): void {
    const firstInvalidControl: HTMLElement = document.querySelector('.ng-invalid') as HTMLElement;
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  backToRegister() {
    this.registrationSuccessful = false;
    this.registerForm.reset();
  }
}
