<div _ngcontent-ng-c3186355447="" class="d-flex flex-row mb-5">
  <h2 _ngcontent-ng-c3186355447="">Leave Calendar</h2>
</div>
<div class="container-fluid">
  <div class="row mt-5">
    <form [formGroup]="eventsForm">

      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-2 ">
          <label for="year" class="form-label">Year</label>
          <input type="number" class="form-control" id="year" formControlName="year" (change)="onChange()">
        </div>
        <div class="col-md-2 col-lg-2 col-sm-2 ">
          <label for="month" class="form-label">Month</label>
          <input type="number" class="form-control" id="month" formControlName="month" (change)="onChange()">
        </div>

      </div>


    </form>
  </div>
  <div class="row  mt-4">
    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th class="small"></th>
            <th *ngFor="let d of month" class="small">{{ d.day }}</th>
          </tr>
          <tr>
            <th class="small">User</th>
            <th *ngFor="let d of month" class="small">{{ d.name }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let e of events">
            <ng-container *ngIf="hasEvents(e)">
              <tr>
                <th class="small">{{e.user.firstName}} {{e.user.lastName}}</th>
                <th class="small" *ngFor="let d of month" [ngClass]="{'table-secondary': isWeekend(d.name)}">
                  <span class="badge rounded-pill" [style.background]="ev.color ? ev.color : '#9999ff'"
                    *ngFor="let ev of getEvents(d.day, e.user)">
                    {{ev.name}}
                  </span>
                </th>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>