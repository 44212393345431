<div *ngIf="user" #importForm>
  <div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label>First Name</label>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="user.firstName">
      </div>
      <div class="mb-3 col-md-6">
        <label>Last Name</label>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="user.lastName">
      </div>
    </div>
    <div class="mb-3 col-md-6">
      <label>Password</label>
      <input type="text" class="form-control form-control-sm" [(ngModel)]="user.password">
    </div>
    <div class="mb-3 col-md-6">
      <label>Email</label>
      <input type="text" class="form-control form-control-sm" [(ngModel)]="user.email">
    </div>
  </div>
  <div class="mb-3">
    <label>Confirm Email</label>
    <select class="form-select form-control-sm" [(ngModel)]="user.confirmedEmail">
      <option value="true" [selected]="user.confirmedEmail ">TRUE</option>
      <option value="false" [selected]="!user.confirmedEmail">FALSE</option>
    </select>
  </div>
  <div class="mb-3">
    <label>Role</label>
    <select id="roles" name="roles" class="form-select form-control form-control-sm" [(ngModel)]="user.roles">
      <option value="MANAGER" [selected]="user.roles[0] === 'MANAGER'">MANAGER</option>
      <option value="EMPLOYEE" [selected]="user.roles[0] === 'EMPLOYEE'">EMPLOYEE</option>
    </select>
  </div>
  <div class="d-flex gap-3">
    <button type="submit" class="btn btn-primary btn-sm ms-auto py-2 px-4" (click)="update(user.id,user.firstName, user.lastName,user.password,user.email,user.confirmedEmail,user.roles)">Submit</button>
    <button type="submit" class="btn btn-secondary btn-sm py-2 px-4" (click)="cancelSubmit()">Cancel</button>
  </div>
</div>