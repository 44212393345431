<nav id="sidebarMenu" [class.narrow]="isNarrow" class="collapse d-lg-block sidebar" *ngIf="isLogin">
    <div class="nav-hammburger-button" [class.is-close]="isSidenavOpen">
        <button class (click)="toggleSidenav()">   
            <mat-icon *ngIf="isSidenavOpen" class="material-icons-outlined">menu</mat-icon>
            <mat-icon *ngIf="!isSidenavOpen" class="material-icons-outlined">menu_open</mat-icon>
        </button>
    </div>
    <div class="position-sticky">
        <ul class=" nav-items">
            <li class="nav-item" routerLinkActive="active" *ngFor="let menuItem of menuItems">
                <a class="nav-link " [class.text-under]="isSidenavOpen" [routerLink]="menuItem.link"
                    *ngIf="isLogin"><mat-icon class="material-icons-outlined">{{ menuItem.icon }}</mat-icon><span
                        class="item-label"> {{ menuItem.label }} </span></a>
            </li>
        </ul>

        <div class="nav-management-container btn-group flex-column" *ngIf="isAdmin">

            <ul class="mx-0 nav-items nav-management">
                <li class="nav-item" [class]="isOpen ? 'nav-management-open' : ''" (click)="toggleMenu()">
                    <span class="nav-link" [class.text-under]="isSidenavOpen"
                        [class]="isOpen ? 'arrow-up' : 'arrow-down'" role='button'>
                        <span
                            class="item-label">MANAGEMENT </span>
                    </span>
                </li>
                <ul *ngIf="isOpen" aria-labelledby="managerMenuButton">
                    <li class="nav-item" routerLinkActive="active" *ngFor="let adminMenuItem of adminMenuItems">
                        <a class="nav-link" [class.text-under]="isSidenavOpen"
                            [routerLink]="adminMenuItem.link"><mat-icon
                                class="material-icons-outlined">{{ adminMenuItem.icon }}</mat-icon> <span
                                class="item-label"> {{ adminMenuItem.label }}</span></a>
                    </li>
                </ul>
            </ul>
        </div>

    </div>
</nav>