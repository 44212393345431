import { Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guards';
import { adminGuard } from '@core/guards/admin.guards';
import { RegisterComponent } from '@pages/register/register.component';
import { HomeComponent } from '@pages//home/home.component';
import { EmployeeDashboardComponent } from '@pages/dashboard/employee-dashboard/employee-dashboard.component';
import { ManagerDashboardComponent } from '@pages/dashboard/manager-dashboard/manager-dashboard.component';
import { AttendanceComponent } from '@pages/attendance/attendance.component';
import { LeaveComponent } from '@pages/leave/leave.component';
import { CalendarComponent } from '@pages/calendar/calendar.component';
import { UsersManagementComponent } from '@management/users-management/users-management.component';
import { OfficeManagementComponent } from '@management/office-management/office-management.component';

import { LeaveManagementComponent } from '@management/leave-management/leave-management.component';
import { LeaveTypeManagementComponent } from '@management/leave-type-management/leave-type-management.component';
import { CompanyInfoManagementComponent } from '@management/company-info-management/company-info-management.component';
import { PublicHolydaysManagementComponent } from '@management/public-holydays-management/public-holydays-management.component';
import { DownloadReportsComponent } from '@pages/download-reports/download-reports.component';
import { AnnualLeaveReportComponent } from '@pages/annual-leave-report/annual-leave-report.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { ActivateEmailComponent } from '@pages/activate-email/activate-email.component';

import {OFFICE_MANAGEMENT_ROUTES} from '@management/office-management/office.routes';
import { USERS_MANAGEMENT_ROUTES } from '@management/users-management/users.routes';

export const routes: Routes = [
  { path: 'register', component: RegisterComponent },
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '/' },
  { path: 'dashboard/employee', component: EmployeeDashboardComponent,canActivate: [authGuard]  },
  { path: 'dashboard/manager',  component: ManagerDashboardComponent,canActivate: [adminGuard]  },
  { path: 'attendance', component: AttendanceComponent, canActivate: [authGuard] },
  { path: 'leave', component: LeaveComponent,canActivate: [authGuard]  },
  { path: 'calendar', component: CalendarComponent,canActivate: [authGuard]  },
  { path: 'management/users', loadChildren: () => Promise.resolve(USERS_MANAGEMENT_ROUTES)  },
  { path: 'management/offices',  loadChildren: () => Promise.resolve(OFFICE_MANAGEMENT_ROUTES) },
  { path: 'management/leave-type', component: LeaveTypeManagementComponent,canActivate: [adminGuard]  },
  { path: 'management/leave', component: LeaveManagementComponent,canActivate: [adminGuard]  },
  {
    path: 'management/company-info',
    component: CompanyInfoManagementComponent,
    canActivate: [adminGuard] 
  },
  {
    path: 'management/public-holydays',
    component: PublicHolydaysManagementComponent,
    canActivate: [adminGuard] 
  },
  { path: 'annual-reports', component: AnnualLeaveReportComponent,canActivate: [adminGuard]  },
  { path: 'download-reports', component: DownloadReportsComponent,canActivate: [adminGuard]  },
  { path: 'activate-email/:token', component: ActivateEmailComponent },
  { path: '**', component: NotFoundComponent },
];
