import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserDTO } from '@model/userDTO';
import moment from 'moment';
import { UsersApiService } from '@api/users.service';
import { DownloadApiService } from '@api/download.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-download-reports',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './download-reports.component.html',
  styleUrl: './download-reports.component.css'
})
export class DownloadReportsComponent {
  reportTypes: Array<{ value: string, name: string }> = [
    { value: "year", name: "Year report" },
    { value: "attendance", name: "Attendance report" }
  ]

  users: Array<UserDTO> = []
  reportsForm: FormGroup = this.fb.group({
    year: [moment().year()],
    reportType: [null],
    user: [null],
    startDate: [(moment().startOf('month')).format("yyyy-MM-DD")],
    endDate: [moment().format("yyyy-MM-DD")]
  });


  constructor(private fb: FormBuilder,
    private downloadApi: DownloadApiService,
    private userApi: UsersApiService) {
    userApi.getUsersList({ pageSize: 100 }).subscribe(value => {
      this.users = value.content
    })
  }

  onSubmit() {
    const year = this.reportsForm.value.year;
    const reportType = this.reportsForm.value.reportType;
    const userId = this.reportsForm.value.user;
    const startDate = this.reportsForm.value.startDate;
    const endDate = this.reportsForm.value.endDate;

    this.download(reportType, year, userId, startDate, endDate)
  }

  download(reportType: string, year: number, userId?: number, startDate?: string, endDate?: string) {

    switch (reportType) {
      case "year":
        let body1: { fileType: string, userId?: number } = { fileType: "CSV" }
        if (userId !== null) body1['userId'] = userId;
        this.downloadApi.getDownloadYearReports(body1).subscribe(this.downloadFile)
        break;

      case "attendance":
        let body2: { fileType: string, startDate?: string, endDate?: string, userId?: number } = { fileType: "CSV", startDate, endDate }
        if (userId !== null) body2['userId'] = userId;
        this.downloadApi.getDownloadAttendanceReports(body2).subscribe(this.downloadFile)
        break;
    }
  }
  downloadFile(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);

    if (fileNameMatch && fileNameMatch[1]) {
      const fileName = fileNameMatch[1];
      const blob = new Blob([response.body as Blob], { type: 'text/csv' });

      // Use a library or create a download link to initiate the download
      // For example:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Handle error: Unable to determine filename
      console.error('Unable to determine filename from Content-Disposition header.');
    }
  }
}
