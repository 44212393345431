import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LeaveTypeDTO } from '@model/leaveTypeDTO';
import { LeaveTypeApiService } from '@api/leave-type.service';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { ManagementHeaderComponent } from '@components/menu/management-header/management-header.component';
import {MatIconModule} from '@angular/material/icon';
import { AlertComponent } from '@components/alert/alert.component';
import { AlertService } from '@alert/alert.service';
@Component({
  selector: 'app-leave-type-management',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ModalComponent, ManagementHeaderComponent,MatIconModule, AlertComponent],
  templateUrl: './leave-type-management.component.html',
  styleUrl: './leave-type-management.component.css'
})
export class LeaveTypeManagementComponent {
  leaveTypeForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    days: ['', [Validators.min(1), Validators.max(365)]],
    strictDays: ['', ],
    halfDay: ['',],
    commentRequired: ['', ],
    autoApprove: ['', ],
    canBeReported:['',],
    color: ['#7ec8e3'] 
  });
  leaveTypeList: Array<LeaveTypeDTO> = [];

  constructor(private fb: FormBuilder, private leaveTypeApi: LeaveTypeApiService,private alertService: AlertService,) {
    this.getAllLeaveType();
    console.log("this.getAllLeaveType();",this.getAllLeaveType())
  }


  onSubmit() {
    if (this.leaveTypeForm.valid) {
      this.leaveTypeApi.postLeaveType({
        name: this.leaveTypeForm.value.name,
        days: this.leaveTypeForm.value.days,
        halfDay: this.leaveTypeForm.value.halfDay,
        strictDays: this.leaveTypeForm.value.strictDays,
        commentRequired: this.leaveTypeForm.value.commentRequired,
        autoApprove: this.leaveTypeForm.value.autoApprove,
        canBeReported: this.leaveTypeForm.value.canBeReported,
        color: this.leaveTypeForm.value.color 
      }).subscribe(()=>{
        this.getAllLeaveType();
        this.alertService.sendAlertSuccess('Leave type was updated succesfuly')
        
      })
    }
  }
  getAllLeaveType() {
    this.leaveTypeApi.getLeaveType({pageSize:100})
    .subscribe(value=>{
      this.leaveTypeList = value.content
      console.log("this.getAllLeaveType();",value.content)
    })
  }
  update(id:number,name:string,days:number,halfDay:boolean,strictDays:boolean,commentRequired:boolean,
    autoApprove:boolean, canBeReported:boolean,color:string) {
    this.leaveTypeApi.patchLeaveType(id,{
      name: name,
      days: days,
      halfDay: halfDay,
      strictDays: strictDays,
      commentRequired:commentRequired,
      autoApprove: autoApprove,
      canBeReported: canBeReported,
      color:color
    }).subscribe(()=>{
      this.getAllLeaveType();
      this.alertService.sendAlertSuccess('Leave type was updated succesfuly');
    })
  }
  delete(id: number) {
    this.leaveTypeApi.deleteLeaveType(id).subscribe(()=>{
      this.getAllLeaveType()
    })
  }
}
