
<app-management-header headTitle="Leave request" actionButtonText="Add new Leave" modalId="leave"></app-management-header>

<div class="container-fluid mt-4 user-management clear-user-agent-styles">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Date From</th>
                <th>Date To</th>
                <th>WorkingDays</th>
                <th>Leave Type</th>
                <th>Half Day</th>
                <th>Comment</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let leave of leaveList">
                <td>{{leave.dateFrom}}</td>
                <td>{{leave.dateTo}}</td>
                <td>{{leave.workingDays}}</td>
                <td>{{leave.leaveType.name}}</td>
                <td>{{leave.leaveType.halfDay}}</td>
                <td>{{leave.comment}}</td>
                <td><span class="badge bg-success" *ngIf="leave.status == 'APPROVED'">{{leave.status}}</span>
                    <span class="badge bg-warning text-dark" *ngIf="leave.status == 'PENDING'">{{leave.status}}</span>
                    <span class="badge bg-danger" *ngIf="leave.status == 'REJECTED'">{{leave.status}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="container-fluid mt-4">
    <div class="row">
        
        <div class="col-md-6" #col2>
            <div class="row">
                <div >
                    <h2>Reports</h2>
                    <ul class="list-group">
                        <div *ngIf="userYearReports.length === 0; else reportList">
                            <!-- Content to display when the list is empty -->
                            <p>No reports available </p>
                        </div>
                        <ng-template #reportList>
                            <!-- Content to display when the list is not empty -->
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center"
                                    *ngFor="let report of userYearReports">
                                    {{report.leaveType.name}} ({{ report.year}})
                                    <span class="badge bg-light text-dark rounded-pill">Days: {{
                                        report.totalLeaveDays}}</span>
                                    <span class="badge bg-light text-dark rounded-pill">Taken: {{
                                        report.takenLeaveDays}}</span>
                                    <span class="badge bg-light text-dark rounded-pill">Carried: {{
                                        report.carriedOverDays}}</span>
                                </li>
                            </ul>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
    </div>


</div>
<app-modal [modalId]="'leave'" title="Add new Leave" [isForm]="true">
    <form [formGroup]="leaveForm" (ngSubmit)="onSubmit()">
        <div class="row mb-3">
            <!-- Leave Type Select Box -->
            <div class="col-md-4 col-sm-12"> <!-- Use col-sm-12 for small screens -->
                <label for="leaveTypeId" class="form-label">Leave Type</label>
                <select class="form-select" id="leaveTypeId" name="leaveTypeId" required
                    (change)="changeStatus()" formControlName="leaveTypeId">
                    <option value="" disabled selected>Select Leave Type</option>
                    <option [value]="leaveType.id" *ngFor="let leaveType of leaveTypeList">{{leaveType.name}}
                    </option>
                    <!-- Add more leave types as needed -->
                </select>
            </div>

            <!-- From Date Datepicker -->
            <div class="col-md-3 col-sm-12 mb-3"> <!-- Use col-sm-12 for small screens -->
                <label for="dateFrom" class="form-label">From Date</label>
                <input type="date" class="form-control" id="dateFrom" name="dateFrom" formControlName="dateFrom"
                    (change)="changeStatus()">
            </div>

            <!-- To Date Datepicker -->
            <div class="col-md-3 col-sm-12 mb-3"> <!-- Use col-sm-12 for small screens -->
                <label for="dateTo" class="form-label">To Date</label>
                <input type="date" class="form-control" id="dateTo" name="dateTo" formControlName="dateTo">
            </div>

            <!-- Half Day Checkbox -->
            <div class="col-md-2 col-sm-12 d-flex align-items-center"> <!-- Use col-sm-12 for small screens -->
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="halfDay" name="halfDay"
                        (change)="changeStatus()" formControlName="halfDay">
                    <label class="form-check-label" for="halfDay">Half Day</label>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Comment Textarea -->
            <div class="col-12">
                <label for="comment" class="form-label">Comment <span *ngIf="selectedLeaveType?.commentRequired"
                        class="badge rounded-pill bg-warning text-dark">Required</span> </label>
                <textarea class="form-control" id="comment" rows="2" formControlName="comment"></textarea>
            </div>
        </div>

        <!-- Submit Button -->
        <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" data-bs-dismiss="modal">Submit</button>
    </div>
    </form>
</app-modal>