import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaveDTO } from '@model/leaveDTO';
import { LeaveApiService } from '@api/leave.service';
import { ModalComponent } from '@components/modal/modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { AlertService } from '@alert/alert.service';

@Component({
  selector: 'app-leave-management',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,ModalComponent,MatIconModule],
  templateUrl: './leave-management.component.html',
  styleUrl: './leave-management.component.css'
})
export class LeaveManagementComponent {
  title = "Timo Leave Request"
  leaveId: number = 0;
  leaveList: Array<LeaveDTO> = []
  leaveStatusForm: FormGroup = this.fb.group({
    comment: [''],
  });
  constructor(private leaveApi: LeaveApiService,private fb: FormBuilder, private alertService: AlertService,){
    this.updateLeave()
  }
  approve(id: number) {
    this.leaveApi.patchLeaveStatus(id,{status:"APPROVED"}).subscribe(()=>{
      this.updateLeave()
      this.alertService.sendAlertSuccess('Leave was aproved');
    })
  }
  reject(id: number) {
    
    this.leaveApi.patchLeaveStatus(id,{status:"REJECTED"}).subscribe(()=>{
      this.updateLeave()
      this.alertService.sendAlertSuccess('Leave was rejected');
    })
  }
  setLeaveId(id:number){
this.leaveId = id;
  }
  updateLeave(){
    this.leaveApi.getLeave({ pageSize: 100 }).subscribe(value => {
      console.log(value.content)
      this.leaveList = value.content;
    })
  }
}
