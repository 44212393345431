import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoginService } from '@login/login.service';
import { AuthApiService } from '@api/auth.service';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  constructor(private loginService: LoginService, private router: Router){
    this.loginService.loginEvent().subscribe((data) => {
      console.log("data.user?.roles", data.user?.roles)
      if (data.user?.roles === 'MANAGER'){
        this.router.navigate(['/dashboard/manager']);
      } else if (data.user?.roles === 'EMPLOYEE') {
        this.router.navigate(['/dashboard/employee']);
      }

    });
  }
  ngOnInit(): void {
    
       if (this.loginService.isLogin()) {
      console.log("data.user?.roles", this.loginService.isLogin())
      if (this.loginService.isLogin()){
        this.router.navigate(['/dashboard/manager']);
      } else {
        this.router.navigate(['/dashboard/employee']);
      }
    }
   
  }
}
