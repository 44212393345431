import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { LoginService } from '@login/login.service';
import { UserDTO } from '@model/userDTO';
import { AuthApiService } from '@api/auth.service';
import {MatIconModule} from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


interface MenuItem {
  label: string;
  link: string;
  icon: string;
}
@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, FontAwesomeModule,MatIconModule],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.css',
})
export class SideBarMenuComponent implements OnInit {
  isSidenavOpen = false;
  isNarrow = false;
  isLogin = false;
  menuItems: MenuItem[] = [];
  adminMenuItems: MenuItem[] = [];


  
  loginForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  user: UserDTO | undefined;
  isAdmin = false;
  isOpen = false;

  constructor(
    private fb: FormBuilder,
    private authApi: AuthApiService,
    private loginService: LoginService,
    private router: Router
  ) {
    // Populate the menu items
    this.menuItems = [
      { label: 'Dashboard', link: '/dashboard/employee', icon: 'grid_view', },
      { label: 'Attendance', link: '/attendance', icon: 'work_history' },
      { label: 'Leave', link: '/leave', icon: 'perm_contact_calendar'},
      { label: 'Calendar', link: '/calendar', icon: 'calendar_month'},
    ];

    this.adminMenuItems = [
      { label: 'Users', link: '/management/users', icon:"group_add" },
      { label: 'Offices', link: '/management/offices', icon: 'maps_home_work'},
      { label: 'Leave Type', link: '/management/leave-type', icon: 'room_preferences' },
      { label: 'Leave Request', link: '/management/leave', icon: 'receipt_long' },
      { label: 'Public Holiday', link: '/management/public-holydays', icon: 'edit_calendar' },
      { label: 'Annual Reports', link: '/annual-reports', icon: 'summarize' },
      { label: 'Download Reports', link: '/download-reports', icon: 'download' },
      { label: 'Company', link: '/management/company-info', icon: 'settings' },
    ];

    this.loginService.loginEvent().subscribe((data) => {
      this.isLogin = data.isLogin;
      this.user = data.user;
      this.isAdmin = data.user?.roles === 'MANAGER';
      this.menuItems[0].link =
        data.user?.roles === 'MANAGER'
          ? '/dashboard/manager'
          : '/dashboard/employee';
    });
  }

  ngOnInit(): void {
    this.loginService.checkCanLogin((token) => {
      this.authApi.postAuthToken(token).subscribe((value) => {
        this.loginService.setData(value);
      });
    });
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
    this.isNarrow = !this.isNarrow;
  }
  toggleMenu() {
    this.isOpen = !this.isOpen;
    console.log('togle', this.isOpen);
  }
  logout() {
    this.loginService.clearTokens();
    this.router.navigate(['/home']);
  }
  onSubmit() {
    if (this.loginForm.valid) {
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;
      this.authApi.postAuthLogin({ email, password }).subscribe((value) => {
        this.loginService.setData(value);
        if(this.isAdmin) { 
          this.router.navigate(['/dashboard/manager'])
        } else {
           this.router.navigate(['/dashboard/employee'])
          }
       
      });
    }
  }
}
