import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { OfficeDTO } from '@model/officeDTO';
import { HolidayDTO } from '@model/holidayDTO';
import { PublicHoliday } from '@model/publicHoliday';
import { CountryDTO } from '@model/countryDTO';
import { RegionDTO } from '@model/regionDTO';
import { CountryApiService } from '@api/country.service';
import moment from 'moment';
import { HolidayApiService } from '@api/holiday.service';
import { OfficeApiService } from '@api/office.service';
import { ManagementHeaderComponent } from '@components/menu/management-header/management-header.component';
import { ModalComponent } from '@components/modal/modal/modal.component';

@Component({
  selector: 'app-public-holydays-management',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule,ManagementHeaderComponent,ModalComponent],
  templateUrl: './public-holydays-management.component.html',
  styleUrl: './public-holydays-management.component.css'
})
export class PublicHolydaysManagementComponent {
  selectedObjectIndex: number[] = [];
  offices: Array<OfficeDTO> = [];
  holidays: Array<HolidayDTO> = []
  publicHolidays: Array<PublicHoliday> = [];
  countryList: Array<CountryDTO> = [];
  officeID: string = "";

  yearAndCountryForm: FormGroup = this.fb.group({
    country: ['', Validators.required],
    year: [moment().year(), Validators.required],
  });


  addForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    localName: ['', Validators.required],
    date: ['', Validators.required],
    country: ['', Validators.required],
    regions: [''],
  });

  listHolidayByOffice(officeId: number): Array<HolidayDTO> {
    return this.holidays.filter(h => h.office.id === officeId)
  }

  getCountry(countryCode: string): CountryDTO {
    return this.countryList.filter(c => c.countryCode === countryCode)[0]
  }

  constructor(
    private countryApi: CountryApiService,
    private holydaysApi: HolidayApiService,
    private officeApi: OfficeApiService,
    private fb: FormBuilder) {

  }
  ngOnInit(): void {
    this.countryApi.getCountryList().subscribe(value => {
      this.countryList = value;
      this.yearAndCountryForm.get('country')?.setValue("RO")
      this.getHolidays(moment().year())
      this.countryApi.getCountryPublicHolidayCY("RO", moment().year()).subscribe(value => {
        this.publicHolidays = value;
      })
    })
    this.officeApi.getOffice({ pageSize: 100 }).subscribe(velue => {
      this.offices = velue.content;
    })
  }

  onValueChange() {
    this.publicHolidays = []
    const countryCode = this.yearAndCountryForm.value.country
    const year = this.yearAndCountryForm.value.year as number
    console.log(year)
    this.countryApi.getCountryPublicHolidayCY(countryCode, year).subscribe(value => {
      this.publicHolidays = value;
    })
    this.getHolidays(year)
  }


  add(officeId: number,
    inputName: HTMLInputElement,
    inputLocalName: HTMLInputElement,
    inputDate: HTMLInputElement,
    inputCountry: HTMLSelectElement,
    inputRegion: HTMLInputElement) {

    const name = inputName.value;
    const localName = inputLocalName.value;
    const date = inputDate.value;
    const country = inputCountry.value;
    const year = this.yearAndCountryForm.value.year as number
    const region: Array<RegionDTO> = inputRegion.value.split(';').map(v => {
      var r = v.split('=')
      return { code: r[0], name: r[1] }
    })
    this.holydaysApi.postHoliday([{ officeId, name, localName, date, country, regions: region }]).subscribe(value => {

      inputName.value = '';
      inputLocalName.value = '';
      inputDate.value = '';
      inputCountry.value = 'RO';
      inputRegion.value = ""

      this.getHolidays(year)
    })
  }
  delete(holidayId: number) {
    const year = this.yearAndCountryForm.value.year as number
    this.holydaysApi.deleteHoliday(holidayId).subscribe(value => {
      this.getHolidays(year)
    })
  }

  submitForm(importForm: HTMLFormElement) {
    var officeId = Number.parseInt((importForm.elements.namedItem("select_office") as HTMLSelectElement).value)
    var importedPublic: Array<PublicHoliday | any> = []
    for (let i = 0; i < importForm.elements.length; i++) {
      if ((importForm.elements.item(i) as HTMLInputElement).type === "checkbox" && (importForm.elements.item(i) as HTMLInputElement).checked) {

        const index = Number.parseInt((importForm.elements.item(i) as HTMLInputElement).id.substring(9))
        if (this.publicHolidays.at(index) !== undefined) importedPublic.push(this.publicHolidays.at(index))
      }

    }

    if (importedPublic.length > 0) {
      const body = importedPublic.map(value => {

        return { officeId, name: value.name, localName: value.localName, date: value.date, country: value.country, regions: value.regions }

      })
      this.holydaysApi.postHoliday(body).subscribe(value => {
        const year = this.yearAndCountryForm.value.year as number
        this.getHolidays(year)
      })
    }
  }

  getHolidays(year: number) {
    this.holydaysApi.getHoliday({ pageSize: 100, year }).subscribe(value => {
      const offices = new Set(value.content.map(h => h.office))
      this.holidays = value.content
    })
  }
}
