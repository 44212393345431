<div>
  <!-- <app-management-header headTitle="User Management" actionButtonText="Add New Member"
    modalId="addUserMember"></app-management-header> -->


  <div class="d-flex flex-row mb-5 management-header" style="border-bottom: 1px solid #d2cdcd;
  padding-bottom: 1rem;">
    <h2>
      <span (click)="setCurrentView('get-users')" style="cursor: pointer;color: #0d6efd;">User Management </span>
    </h2>
    <h3 *ngIf="currentView === 'create-user'" style="color: grey;align-self: flex-end;"> / Create User </h3>
    <h3 *ngIf="currentView === 'update-user'" style="color: grey;align-self: flex-end;"> / Update User </h3>
    <h3 *ngIf="currentView === 'update-user'" class="ms-auto">{{selectedUser?.firstName}} {{selectedUser?.lastName}}
    </h3>
    <button *ngIf="currentView === 'get-users'" routerLink="./user/create" class="ms-auto btn btn-sm btn-primary ps-4 py-2" >
      <span style="padding-right: 8px;" >Add new Member</span>
    </button>
  </div>

  <app-get-users *ngIf="currentView === 'get-users'" (selectedUser)="setSelectedUser($event)"
    (changeView)="setCurrentView($event)"></app-get-users>
  <app-create-user *ngIf="currentView === 'create-user'" (changeView)="setCurrentView($event)" (userId)="setUserId($event)"></app-create-user>
  <app-update-user *ngIf="currentView === 'update-user'" [user]="selectedUser" (changeView)="setCurrentView($event)">
  </app-update-user>
</div>

<div *ngIf="currentView === 'user-created'" class="row justify-content-center" style="margin-top: 100px;">
  <div class="col-md-6 text-center">
    <h1>UserId:  {{userId}}</h1>
    <h2 class="mb-4">Member Added Successfully</h2>
    <p class="lead">The member has been successfully added to the database.</p>
    <hr>
    <p>Member has no office. </p>
   <app-add-user-to-office [selectedUserID]="userId"></app-add-user-to-office>
        <p style="margin-top: 40px;">You can add user  later from the <a routerLink="/management/office">Office Management </a></p>
        <hr>
        <button class="btn btn-primary mx-2" (click)="setCurrentView('create-user')">Add New Member</button>    
    <button class="btn btn-secondary mx-2" (click)="setCurrentView('get-users')">Back to User Management</button>
  </div>
</div>









<!-- <div>
  <app-management-header headTitle="User Management" actionButtonText="Add New Member"
    modalId="addUserMember"></app-management-header>
  <app-get-users #getUsersComponent></app-get-users>

  <app-modal [modalId]="'addUserMember'" modalTitle="Add new member">
    <div *ngIf="!showCreateUser; else memberWasCreated">
      <app-create-user (userCreated)="handleUserCreated()"></app-create-user>
    </div>
    <ng-template #memberWasCreated>
      <div class="row justify-content-center" style="margin-top: 100px;">
        <div class="col-md-6 text-center">
          <h2 class="mb-4">Member Added Successfully</h2>
          <p class="lead">The member has been successfully added to the database.</p>
          <button class="btn btn-primary mx-2" (click)="showCreateUser = true">Add New Member</button>
          <button class="btn btn-success mx-2"><a class="text-light" routerLink="/management/office"
              data-bs-dismiss="modal">Add Member to Office</a></button>
          <button class="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </ng-template>
  </app-modal>

  <app-modal [modalId]="'updateUserMember'" modalTitle="Update member info">
   <app-update-user> </app-update-user>
  </app-modal>

</div> -->