import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { LoginService } from '../../service/login/login.service';
import { UserDTO } from '../../service/model/userDTO';
import { AuthApiService } from '../../service/api/auth.service';
interface MenuItem {
  label: string;
  link: string;
}
@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent implements OnInit {
  isSidenavOpen = false;
  isLogin = false;
  menuItems: MenuItem[] = [];
  adminMenuItems: MenuItem[] = [];
  loginForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  user: UserDTO | undefined;
  isAdmin = false;
  isOpen = false;

  constructor(
    private fb: FormBuilder,
    private authApi: AuthApiService,
    private loginService: LoginService,
    private router: Router
  ) {
    // Populate the menu items
    this.menuItems = [
      { label: 'Dashboard', link: '/dashboard/employee' },
      { label: 'Attendance', link: '/attendance' },
      { label: 'Leave', link: '/leave' },
      { label: 'Calendar', link: '/calendar' },
    ];

    this.adminMenuItems = [
      { label: 'Users', link: '/management/users' },
      { label: 'Office', link: '/management/office' },
      { label: 'LeaveType', link: '/management/leave-type' },
      { label: 'Leave', link: '/management/leave' },
      { label: 'Company', link: '/management/company-info' },
      { label: 'Public Holiday', link: '/management/public-holydays' },
      { label: 'Annual Reports', link: '/annual-reports' },
      { label: 'Download Reports', link: '/download-reports' },
    ];

    this.loginService.loginEvent().subscribe((data) => {
      this.isLogin = data.isLogin;
      this.user = data.user;
      this.isAdmin = data.user?.roles === 'MANAGER';
      this.menuItems[0].link =
        data.user?.roles === 'MANAGER'
          ? '/dashboard/manager'
          : '/dashboard/employee';
    });
  }

  ngOnInit(): void {
    this.loginService.checkCanLogin((token) => {
      this.authApi.postAuthToken(token).subscribe((value) => {
        this.loginService.setData(value);
      });
    });
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }
  toggleMenu() {
    this.isOpen = !this.isOpen;
    console.log('togle', this.isOpen);
  }
  logout() {
    this.loginService.clearTokens();
    this.router.navigate(['/home']);
  }
  onSubmit() {
    if (this.loginForm.valid) {
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;
      this.authApi.postAuthLogin({ email, password }).subscribe((value) => {
        this.loginService.setData(value);
        if(this.isAdmin) { 
          this.router.navigate(['/dashboard/manager'])
        } else {
           this.router.navigate(['/dashboard/employee'])
          }
      });
    }
  }
}
