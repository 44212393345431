<div *ngIf="loading" class="text-center mt-5">
  <p>Loading...</p>
</div>
<div *ngIf="error" class="text-center mt-5">
  <p>{{ error }}</p>
</div>
<div *ngIf="!loading && !error && dashboardData">
  <h2 class="text-center mt-3">Manager Dashboard</h2>
  <div class="container mt-5">
    <div class="row">
      <!-- Total Users Card -->
      <div class="col-md-4 mb-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <div> <mat-icon class="material-icons-outlined">group</mat-icon>
              <h5 class="card-title"> Total Users </h5>
            </div>
            <p class="card-text">{{ dashboardData.totalUsers }}</p>
          </div>
        </div>
      </div>
      <!-- Attendance Cards Grouped -->
      <div class="col-md-8 mb-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <div>
              <mat-icon class="material-icons-outlined">event_available</mat-icon>
              <h5 class="card-title "> Attendance Today </h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p class="card-text">With Attendance: {{ dashboardData.usersWithAttendanceToday }}</p>
              </div>
              <div class="col-md-6">
                <p class="card-text">Without Attendance: {{ dashboardData.usersWithoutAttendanceToday }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Offices Card -->
      <div class="col-md-4 mb-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <div>
              <mat-icon class="material-icons-outlined">business</mat-icon>
              <h5 class="card-title">

                Offices
              </h5>
            </div>
            <p class="card-text">{{ dashboardData.office }}</p>

          </div>
        </div>
      </div>
      <!-- Happy Birthday Card -->
      <div class="col-md-4 mb-4" *ngIf="dashboardData.happyBirthday">
        <div class="card h-100 text-center">
          <div class="card-body">
            <div>
              <mat-icon class="material-icons-outlined">cake</mat-icon>
              <h5 class="card-title">

                Happy Birthday
              </h5>
            </div>
            <p class="card-text">Happy birthday to me!</p>
          </div>
        </div>
      </div>
      <!-- Employee Birthday Card -->
      <div class="col-md-4 mb-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <div>
              <mat-icon class="material-icons-outlined">celebration</mat-icon>
              <h5 class="card-title">

                Employee Birthdays
              </h5>
            </div>
            <div *ngFor="let employer of dashboardData.employersBirthday">
              <p class="card-text">{{ employer.firstName }} {{ employer.lastName }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Pending Leave Request Card with Conditional Styling -->
      <div class="col-md-4 mb-4">
        <a [routerLink]="['/management/leave']" class="card-link">
          <div class="card h-100 text-center"
            [ngClass]="{'bg-success text-white': !dashboardData.pendingLeaveRequest, 'bg-warning text-dark': dashboardData.pendingLeaveRequest}">
            <div class="card-body">
              <div>
              <mat-icon class="material-icons-outlined">pending_actions</mat-icon>
              <h5 class="card-title">
             
                Pending Leave Requests
              </h5>
            </div>
              <p class="card-text">{{ dashboardData.pendingLeaveRequest || 'No pending requests' }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>