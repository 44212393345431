import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
  UrlTree,
} from '@angular/router';
import { inject } from '@angular/core';
import { LoginService } from '@login/login.service';

export const adminGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
  const loginService = inject(LoginService);
  const router = inject(Router);
  // console.log('ADminGuards: isLogin---->', loginService.isLogin());
  // console.log(
  //   'ADminGuards: getAccessTokek ---->',
  //   loginService.isValidToken(loginService.getAccessToken())
  // );
  // console.log(loginService.getAccessToken());
  // console.log('ADminGuards isUserAdmin() ---->', loginService.isUserAdmin());
  // console.log(
  //   'ADminGuards getRefreshToken ---->',
  //   loginService.isValidToken(loginService.getRefreshToken())
  // );

  if (
    loginService.isLogin() &&
    loginService.isValidToken(loginService.getAccessToken())
  ) {
    if (loginService.isUserAdmin()) {
      return true;
    } else {
      return router.createUrlTree(['/home']); 
    }
  } else {
    const refreshed = await loginService.refreshTokenIfNeeded();
    if (
      refreshed &&
      loginService.isLogin() &&
      loginService.isValidToken(loginService.getAccessToken())
    ) {
      return true;
    } else {
      return router.createUrlTree(['/home']);
    }
  }
};
